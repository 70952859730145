import React, { FC } from "react";
import { SvgIconProps } from "@mui/material";

interface Props {
  Icon: FC<SvgIconProps<"svg">>;
}

const EmptyStateIcon: React.FC<Props> = ({ Icon }) => {
  return <Icon sx={{ color: "grey.700" }} fontSize="large" />;
};

export default EmptyStateIcon;
