import { createRoot } from "react-dom/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Highcharts from "highcharts";
import exportingModule from "highcharts/modules/exporting";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
import App from "./App";

exportingModule(Highcharts);
offlineExporting(Highcharts);
exporting(Highcharts);

const container = document.getElementById("root");
const root = createRoot(container as Element);
root.render(<App />);
