import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import TreePanelToggle from "../TreePanelToggle";
import ViewContext from "../../ViewContext";

type Props = Record<string, never>;

const width = 28;

const DesktopToggleIcon: React.FC<Props> = () => {
  const theme = useTheme();
  const { isLeftPanelOpen } = useContext(ViewContext);

  const [toggleStyleOverrides, toolTipText] = isLeftPanelOpen
    ? [{ visibility: "hidden", opacity: 0 }, "Hide Panel"]
    : [{ visibility: "visible", opacity: 1 }, "Show Panel"];

  return (
    <Tooltip title={toolTipText} placement="right">
      <TreePanelToggle
        styles={{
          position: "absolute",
          right: -(width / 2),
          top: 34,
          width,
          zIndex: theme.zIndex.drawer,
          transition: `visibility 0s linear ${theme.transitions.duration.shorter}ms, opacity ${theme.transitions.duration.shorter}ms`,
          ...toggleStyleOverrides,
        }}
      />
    </Tooltip>
  );
};

export default DesktopToggleIcon;
