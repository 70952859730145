import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { createHttpLink } from "apollo-link-http";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { Auth } from "aws-amplify";

const url = process.env.GRAPHQL_ENDPOINT as string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const region = (window as any).REGION || "us-west-2";
const auth = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: "AMAZON_COGNITO_USER_POOLS" as any,
  jwtToken: async () => {
    try {
      return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (e) {
      window.location.reload();
    }
  },
};

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAuthLink({ url, region, auth }) as any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink as any),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default client as any;
