import {
  AnyVal,
  DaysOfTheWeek,
  MultiSelectDropdown,
  SensorStatus,
  SpaceNode,
  SpaceNodeByIdMap,
  UserRole,
  ZoneStatus,
} from "./interfaces";
import { gatherParentNodeList } from "./util/spaceTrees";
import { UserRoleSubtitle } from "./util/copy";
import { Map } from "./interfaces";

export const isEmailValid = (email: string) => {
  const VALID_EMAIL_CHARS =
    /^(([^<>(\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return Boolean(email.match(VALID_EMAIL_CHARS));
};

export const genericErrorString =
  "Unexpected error. Please try again or contact us.";

export const CIRCULAR_PROGRESS_BUTTON_SIZE = 20;

export const daysOfTheWeek = [
  { value: 2, label: DaysOfTheWeek.Monday },
  { value: 3, label: DaysOfTheWeek.Tuesday },
  { value: 4, label: DaysOfTheWeek.Wednesday },
  { value: 5, label: DaysOfTheWeek.Thursday },
  { value: 6, label: DaysOfTheWeek.Friday },
  { value: 7, label: DaysOfTheWeek.Saturday },
  { value: 1, label: DaysOfTheWeek.Sunday },
];

export const sensorStatusItems = [
  { label: "Installed", value: SensorStatus.Installed },
  { label: "In Review", value: SensorStatus.InReview },
  { label: "Reset Sensor", value: SensorStatus.Reset },
  { label: "Replace Sensor", value: SensorStatus.Replace },
  { label: "Empty", value: SensorStatus.Empty },
];

export const zoneStatusItems = [
  { label: "Live", value: ZoneStatus.Live },
  { label: "Needs Setup", value: ZoneStatus.Setup },
  { label: "Needs Head Counts", value: ZoneStatus.Calibration },
  { label: "In Review", value: ZoneStatus.Inreview },
  { label: "Training", value: ZoneStatus.Training },
  { label: "Inactive", value: ZoneStatus.Inactive },
];

export const gatherBreadcrumbList = (
  spaceId: number,
  spaceNodeMap: SpaceNodeByIdMap,
  options?: { hideCustomerName: boolean | undefined }
) => {
  const parentNodeList: SpaceNode[] = [];
  const currentNode = spaceNodeMap[spaceId];
  gatherParentNodeList(spaceNodeMap, parentNodeList, currentNode);
  if (options?.hideCustomerName) {
    if (parentNodeList[parentNodeList.length - 1].customerName) {
      parentNodeList.pop();
    }
  }
  return parentNodeList.reverse();
};

export const DataStreamApiUri = process.env.DATA_STREAM_URI;

export const FetchTimeoutMs = 60 * 1000;

export const deriveUserRoleSubtitle = (role: UserRole) => {
  switch (role) {
    case UserRole.SuperAdmin:
      return UserRoleSubtitle.SuperAdmin;
    case UserRole.Admin:
      return UserRoleSubtitle.Admin;
    case UserRole.User:
      return UserRoleSubtitle.User;
    case UserRole.Setup:
      return UserRoleSubtitle.Setup;
  }
};

export const deriveUserRoleTitle = (role: string) => {
  switch (role) {
    case UserRole.SuperAdmin:
      return "Super Admin";
    case UserRole.Admin:
      return "Admin";
    case UserRole.User:
      return "User";
    case UserRole.Setup:
      return "Setup";
  }
};

export const deriveSelectedDropdownValues = (
  value: string[],
  items: AnyVal[],
  selectedItems: AnyVal[]
) => {
  let newItems = value;
  // When AllValue is selected we check if all available statuses are already selected
  // We select or deselect all accordingly
  if (value[value.length - 1] === MultiSelectDropdown.AllValue) {
    newItems =
      selectedItems.length === items.length
        ? []
        : items.map((item) => item.value);
  }
  return newItems;
};

// assumes string or int list
export const listToMap = (list: string[] | number[]): Map => {
  const map: Map = {};
  list.forEach((id) => {
    map[id] = true;
  });
  return map;
};
