import { Map, SpaceNode, SpaceNodeByIdMap, SpaceType } from "../interfaces";

export function pruneDescendantSpaceIds(
  topLevelTrees: SpaceNode[],
  selectedSpaceIdMap: {
    [index: string]: boolean;
  }
) {
  const prunedSpaceIdList: number[] = [];
  const recursiveHelper = (currentNode: SpaceNode) => {
    if (selectedSpaceIdMap[currentNode.id]) {
      prunedSpaceIdList.push(currentNode.id);
      return;
    }

    if (currentNode.children) {
      currentNode.children.forEach(recursiveHelper);
    }
  };

  topLevelTrees.forEach(recursiveHelper);

  return prunedSpaceIdList;
}

export function gatherDescendantSpaceIds(
  treeNode: SpaceNode,
  descendantSpaceIdList: string[]
) {
  descendantSpaceIdList.push(String(treeNode.id));
  if (treeNode.children) {
    treeNode.children.forEach((child: SpaceNode) =>
      gatherDescendantSpaceIds(child, descendantSpaceIdList)
    );
  }
}

export function gatherDescendantSpaceIdsWithMap(
  treeNode: SpaceNode,
  descendantSpaceIdList: string[],
  map: Map
) {
  descendantSpaceIdList.push(String(treeNode.id));
  map[treeNode.id] = true;
  if (treeNode.children) {
    treeNode.children.forEach((child: SpaceNode) =>
      gatherDescendantSpaceIdsWithMap(child, descendantSpaceIdList, map)
    );
  }
}

function getSelectedParentIdsHelper(
  currentNode: SpaceNode,
  selectedNodeIdMap: { [spaceId: string]: boolean },
  nodesById: SpaceNodeByIdMap,
  selectedParentsById: { [spaceId: string]: boolean }
) {
  if (!currentNode.parentId) {
    return;
  }

  const parent = nodesById[currentNode.parentId];

  if (!parent) {
    return;
  }
  // if all siblings of the current node are selected
  if (
    parent.children.every(
      (child) => selectedNodeIdMap[child.id] || selectedParentsById[child.id]
    )
  ) {
    // select parent, and see if its parent needs to be selected
    selectedParentsById[parent.id] = true;
    getSelectedParentIdsHelper(
      parent,
      selectedNodeIdMap,
      nodesById,
      selectedParentsById
    );
  }
}

export function getSelectedParentIds(
  node: SpaceNode,
  selectedNodeIdMap: { [spaceId: string]: boolean },
  nodesById: SpaceNodeByIdMap
) {
  const selectedParentsById: { [spaceId: string]: boolean } = {};
  getSelectedParentIdsHelper(
    node,
    selectedNodeIdMap,
    nodesById,
    selectedParentsById
  );
  return selectedParentsById;
}

function getParentsByIdMapHelper(
  currentNode: SpaceNode,
  byIdMap: SpaceNodeByIdMap,
  parentsByIdMap: SpaceNodeByIdMap
) {
  parentsByIdMap[currentNode.id] = currentNode;
  if (currentNode.parentId && byIdMap[currentNode.parentId]) {
    getParentsByIdMapHelper(
      byIdMap[currentNode.parentId],
      byIdMap,
      parentsByIdMap
    );
  }
}

export function getParentsByIdMap(node: SpaceNode, byIdMap: SpaceNodeByIdMap) {
  const parentsByIdMap: SpaceNodeByIdMap = {};
  if (node.parentId && byIdMap[node.parentId]) {
    getParentsByIdMapHelper(byIdMap[node.parentId], byIdMap, parentsByIdMap);
  }
  return parentsByIdMap;
}

export function gatherAllParentIds(
  spaceNodeByIdMap: SpaceNodeByIdMap,
  parentIdMap: SpaceNodeByIdMap,
  currentNode: SpaceNode
) {
  if (currentNode.parentId) {
    const parentNode = spaceNodeByIdMap[currentNode.parentId];
    if (parentNode) {
      parentIdMap[currentNode.parentId] = parentNode;
      gatherAllParentIds(spaceNodeByIdMap, parentIdMap, parentNode);
    }
  }
}

export function gatherParentNodeList(
  spaceNodeByIdMap: SpaceNodeByIdMap,
  parentNodeList: SpaceNode[],
  currentNode: SpaceNode
) {
  if (currentNode.parentId) {
    const parentNode = spaceNodeByIdMap[currentNode.parentId];
    if (parentNode) {
      parentNodeList.push(parentNode);
      gatherParentNodeList(spaceNodeByIdMap, parentNodeList, parentNode);
    }
  }
}

export function gatherParentIdList(
  spaceNodeByIdMap: SpaceNodeByIdMap,
  parentIdList: string[],
  currentNode: SpaceNode
) {
  if (currentNode.parentId) {
    const parentNode = spaceNodeByIdMap[currentNode.parentId];
    if (parentNode) {
      parentIdList.push(String(parentNode.id));
      gatherParentIdList(spaceNodeByIdMap, parentIdList, parentNode);
    }
  }
}

export function gatherDescendantsMap(
  selectedMapIds: SpaceNodeByIdMap,
  treeNode: SpaceNode
) {
  selectedMapIds[treeNode.id] = treeNode;
  if (treeNode.children) {
    treeNode.children.forEach((child: SpaceNode) =>
      gatherDescendantsMap(selectedMapIds, child)
    );
  }
}

export function getSpaceNodesByIdMap(spaceTrees: SpaceNode[]) {
  const spaceNodesByIdMap: SpaceNodeByIdMap = {};
  spaceTrees.forEach((spaceTree: SpaceNode) => {
    gatherDescendantsMap(spaceNodesByIdMap, spaceTree);
  });
  return spaceNodesByIdMap;
}

function getDescendantsByIdMapHelper(
  currentNode: SpaceNode,
  descendantsMap: SpaceNodeByIdMap
) {
  descendantsMap[currentNode.id] = currentNode;
  if (currentNode.children) {
    currentNode.children.forEach((child: SpaceNode) =>
      getDescendantsByIdMapHelper(child, descendantsMap)
    );
  }
}

export function getDescendantsByIdMap(treeNode: SpaceNode) {
  const descendantsMap: SpaceNodeByIdMap = {};
  if (treeNode.children) {
    treeNode.children.forEach((child: SpaceNode) =>
      getDescendantsByIdMapHelper(child, descendantsMap)
    );
  }
  return descendantsMap;
}

function getDescendantIdsHelper(
  currentNode: SpaceNode,
  descendantIds: string[],
  options: Map = {}
) {
  if (!options.ignoreFiltration || currentNode.type !== SpaceType.Filtration) {
    // If we arent checking foe either occupancy or visitors, we want to add all nodes
    if (
      !options.onlyOccupancy &&
      !options.onlyVisitors &&
      !options.onlyDwelltime
    ) {
      descendantIds.push(String(currentNode.id));
    }
    // If we are checking for occupancy, we only want to add nodes that have occupancy
    if (options.onlyOccupancy && currentNode.occupancy) {
      descendantIds.push(String(currentNode.id));
    }
    // If we are checking for visitors, we only want to add nodes that have visitors
    if (options.onlyVisitors && currentNode.visitorship) {
      descendantIds.push(String(currentNode.id));
    }
    // If we are checking for dwelltime, we only want to add nodes that have dwelltime
    if (options.onlyDwelltime && currentNode.dwelltime) {
      descendantIds.push(String(currentNode.id));
    }
  }
  if (currentNode.children) {
    currentNode.children.forEach((child: SpaceNode) =>
      getDescendantIdsHelper(child, descendantIds, options)
    );
  }
}

export function getDescendantIds(treeNode: SpaceNode, options: Map = {}) {
  const descendantIds: string[] = [];
  getDescendantIdsHelper(treeNode, descendantIds, options);
  return descendantIds;
}

// helps components which use the SpaceTree component manage its state
export function getSpaceIdSelectHandler(
  {
    selectedList,
    selectedMap,
  }: { selectedList: string[]; selectedMap: { [spaceId: string]: boolean } },
  occuUserSpaceNodeMap: SpaceNodeByIdMap
) {
  const handleSpaceIdSelect = (selectedSpaceId: string) => {
    const isSelected = selectedMap[selectedSpaceId];
    const selectedNode = occuUserSpaceNodeMap[selectedSpaceId];
    const descendantsByIdMap = getDescendantsByIdMap(selectedNode);
    const parentNodeMap = getParentsByIdMap(selectedNode, occuUserSpaceNodeMap);

    // when a space is already selected, we unselect its parents and descendants
    if (isSelected) {
      return selectedList.filter(
        (spaceId) =>
          !parentNodeMap[spaceId] &&
          !descendantsByIdMap[spaceId] &&
          spaceId !== selectedSpaceId
      );
    } else {
      // if a space gets selected, we check to see if its parent
      // should get autoselected because all of its children are selected
      const newlySelectedParentsNodeMap = getSelectedParentIds(
        selectedNode,
        { ...selectedMap, [selectedSpaceId]: true },
        occuUserSpaceNodeMap
      );

      return [
        ...selectedList,
        ...Object.keys(descendantsByIdMap),
        ...Object.keys(newlySelectedParentsNodeMap),
        selectedSpaceId,
      ];
    }
  };
  return handleSpaceIdSelect;
}

export function deriveCustomerNameOfSpace(
  nodes: { [spaceId: string]: SpaceNode },
  spaceId: string
): string {
  if (nodes[spaceId].customerName) {
    return nodes[spaceId].customerName;
  }
  const parentId = nodes[spaceId].parentId;
  if (nodes[parentId]) {
    return deriveCustomerNameOfSpace(nodes, parentId.toString());
  }
  return "";
}
