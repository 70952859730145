import {
  UserRole,
  SpaceNodeByIdMap,
  UserWithLocations,
  OccuUser,
  UserStatus,
} from "../interfaces";

export const isAdmin = (role: string) => {
  return role === UserRole.SuperAdmin || role === UserRole.Admin;
};

export const isSuperAdmin = (role: string) => {
  return role === UserRole.SuperAdmin;
};

export const isSetupRole = (role: string) => {
  return role === UserRole.Setup;
};

export const canUserEditUser = (
  loggedInUser: OccuUser,
  userToEdit: UserWithLocations,
  loggedInUserNodes: SpaceNodeByIdMap
) => {
  if (userToEdit.status !== UserStatus.Active) return false;
  if (!isAdmin(loggedInUser.role)) return false;
  if (isAdmin(loggedInUser.role) && isSuperAdmin(userToEdit.role)) {
    return false;
  }
  const userToEditNodes = userToEdit.userLocations.locations;
  // If no location permissions, allow edit
  if (!userToEditNodes) return true;
  // Check if logged in user has all of user to edit's nodes
  if (
    userToEditNodes.some(({ id }) => {
      return !loggedInUserNodes[id];
    })
  ) {
    return false;
  }
  return true;
};
