import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { gatherBreadcrumbList } from "../../util";
import ViewContext from "../../ViewContext";
import TreeContext from "../../TreeContext";

interface Props {
  spaceId: number;
  isSizeSmall?: boolean;
  includeSelf?: boolean;
  hideCustomerName?: boolean;
}

const CrumbText = ({ text, id }: { text: string; id: number }) => (
  <Typography variant="body2" color="grey.500" key={id}>
    {text}
  </Typography>
);

const SpaceBreadCrumbsById: React.FC<Props> = ({
  spaceId,
  isSizeSmall = false,
  includeSelf,
  hideCustomerName,
}) => {
  const { isMobileSized } = useContext(ViewContext);
  const { nodes } = useContext(TreeContext);
  const { name } = nodes[spaceId];
  const breadCrumbList = gatherBreadcrumbList(spaceId, nodes, {
    hideCustomerName,
  });
  if (breadCrumbList.length > 2 && isMobileSized) {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="grey.500">
          {breadCrumbList[0].name}
        </Typography>
        <Typography color="grey.500" mx={2}>
          /
        </Typography>
        <Typography variant="body2" color="grey.500">
          ...
        </Typography>
        <Typography color="grey.500" mx={2}>
          /
        </Typography>
        <Typography variant="body2" color="grey.500" noWrap>
          {` ${breadCrumbList[breadCrumbList.length - 1].name}`}
        </Typography>
      </Box>
    );
  }
  return (
    <Breadcrumbs
      aria-label={`${name} breadcrumbs`}
      sx={{ color: "grey.500" }}
      maxItems={isSizeSmall ? 3 : 10}
    >
      {breadCrumbList.map(({ name, id }) => (
        <CrumbText text={name} id={id} key={`crumb-${id}`} />
      ))}
      {includeSelf && <CrumbText text={name} id={spaceId} />}
    </Breadcrumbs>
  );
};

export default SpaceBreadCrumbsById;
