import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyStateCardContent from "../EmptyStateCardContent";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Box from "@mui/material/Box";

interface Props {
  buttonLabel: string;
  message: string;
  path: string;
}

const GenericError: React.FC<Props> = ({ buttonLabel, message, path }) => {
  const navigate = useNavigate();

  const handleNavigationClick = () => {
    navigate(path);
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <EmptyStateCardContent
        action={{ onClick: handleNavigationClick, label: buttonLabel }}
        Icon={
          <ReportGmailerrorredIcon
            sx={{ color: "grey.700" }}
            fontSize="large"
          />
        }
        text={message}
      />
    </Box>
  );
};

export default GenericError;
