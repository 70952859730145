import React, { Dispatch } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Action,
  StandardHourTabState,
  handleRuleNameChange,
} from "../../state/hours-dialog";
import HoursForm from "../HoursForm";
import DialogHeader from "components/DialogHeader";
import TextField from "@mui/material/TextField";
import { overlappingTimeRangeError } from "../../util/copy";

interface Props {
  isGlobalRule: boolean;
  isOpen: boolean;
  dispatch: Dispatch<Action>;
  standardTab: StandardHourTabState;
  isLoading: boolean;
  isDisabled: boolean;
  errorMessage: string;
  hasOverlap: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onAssignHourRuleOptionClick: () => void;
  isHoursTab?: boolean;
}

const CreateHourRuleDialog: React.FC<Props> = ({
  isGlobalRule,
  isOpen,
  dispatch,
  standardTab: { ruleName, newWeekdaysWithHours },
  isLoading,
  isDisabled,
  errorMessage,
  hasOverlap,
  onSubmit,
  onClose,
  onAssignHourRuleOptionClick,
  isHoursTab,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      sx={{ " & .MuiPaper-root": { maxWidth: 1100 } }}
      fullWidth
    >
      <DialogHeader useForestTheme title={"Manage Hours"} onClose={onClose} />
      <DialogContent dividers sx={{ pt: 4 }}>
        {!isGlobalRule && !isHoursTab && (
          <Box mb={4}>
            <Typography color="grey.600" gutterBottom>
              Create a new hour rule for this space below by moving the sliders
              and/or using the editing controls. If you have special hours
              during holidays, breaks, or other occasions you can input or edit
              these as well below.
            </Typography>
            <Typography color="grey.600">
              You can also{" "}
              <Link
                underline="none"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={onAssignHourRuleOptionClick}
              >
                assign a different existing hour rule
              </Link>{" "}
              to this space from a list of hour rules that belong to you. from a
              list of rules that belong to you.
            </Typography>
          </Box>
        )}
        {isGlobalRule && (
          <Box my={4}>
            <Typography color="grey.600" gutterBottom>
              Edit the default hours setup for this space below by moving the
              sliders and/or using the editing controls (you will create a new
              hour rule for this space). If you have special hours during
              holidays, breaks, or other occasions you can input or edit these
              as well below.
            </Typography>
            <Typography color="grey.600">
              You can also{" "}
              <Link
                underline="none"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={onAssignHourRuleOptionClick}
              >
                assign a different existing hour rule
              </Link>{" "}
              to this space from a list of hour rules that belong to you.
            </Typography>
          </Box>
        )}
        <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
          <TextField
            disabled={isLoading}
            value={ruleName}
            onChange={handleRuleNameChange(dispatch)}
            placeholder="Name your hour rule"
            fullWidth
            size="small"
            autoComplete="rule-name"
            label="Hour Rule Name"
            sx={{
              mt: 4,
            }}
          />
          <Box flex={1}>
            <HoursForm
              newWeekdaysWithHours={newWeekdaysWithHours}
              dispatch={dispatch}
              sliderMaxWidth={660}
              isLoading={isLoading}
              tabName="standardTab"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          {errorMessage && (
            <Box display="flex" justifyContent="end" mt={2}>
              <Typography color="error" variant="body2" justifySelf="end">
                {errorMessage}
              </Typography>
            </Box>
          )}
          {hasOverlap && (
            <Box display="flex" justifyContent="end" mt={2}>
              <Typography color="error" variant="body2" justifySelf="end">
                {overlappingTimeRangeError}
              </Typography>
            </Box>
          )}
        </Box>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={isDisabled}
          onClick={onSubmit}
        >
          Create Hour Rule
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default CreateHourRuleDialog;
