import React from "react";
import Button from "@mui/material/Button";
import { ButtonProps } from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

interface Props {
  action?: { onClick: () => void; label: string };
  buttonColor?: ButtonProps["color"];
  Icon: React.ReactNode;
  text: string;
  subtitle?: string | React.ReactNode;
}

const EmptyStateCardContent: React.FC<Props> = ({
  action,
  Icon,
  buttonColor = "primary",
  text,
  subtitle,
}) => {
  return (
    <CardContent
      sx={{
        display: "inline-block",
        textAlign: "center",
        maxWidth: 500,
        width: "100%",
      }}
    >
      {Icon}
      <Typography mt={1} mb={1}>
        {text}
      </Typography>
      {subtitle && (
        <Typography variant="body2" color="grey.500" mx={4}>
          {subtitle}
        </Typography>
      )}
      {action && (
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 6, maxWidth: 340 }}
          onClick={action.onClick}
          color={buttonColor}
        >
          {action.label}
        </Button>
      )}
    </CardContent>
  );
};

export default EmptyStateCardContent;
