import React, { useContext, useCallback } from "react";
import { BoxProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { IconProps } from "@mui/material/Icon";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewContext from "../../ViewContext";
import { lightShadow } from "../../theme";
import { leftPanelToggleIconId } from "../../theme";

interface Props {
  styles?: BoxProps["sx"];
}

// mui.com/material-ui/react-tooltip/#custom-child-element
const TreePanelToggle = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { styles } = props;
    const { handleLeftPanelToggle, isLeftPanelOpen } = useContext(ViewContext);
    const iconProps = {
      xs: { color: "grey.600" } as IconProps["sx"],
      fontSize: "inherit" as IconProps["fontSize"],
    };

    const Icon = useCallback(() => {
      if (isLeftPanelOpen) {
        return <ChevronLeftIcon {...iconProps} />;
      }
      return <ChevronRightIcon {...iconProps} />;
    }, [isLeftPanelOpen]);

    return (
      <IconButton
        ref={ref}
        {...props}
        onClick={handleLeftPanelToggle}
        size="small"
        sx={{
          bgcolor: "white",
          border: 1,
          borderColor: "grey.300",
          boxShadow: lightShadow,
          ...styles,
        }}
        id={leftPanelToggleIconId}
      >
        <Icon />
      </IconButton>
    );
  }
);

export default TreePanelToggle;
