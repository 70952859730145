import { useEffect, useState } from "react";

export const useRefDimensions = (ref: React.RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  function updateDimensions() {
    if (ref.current) {
      const {
        current: { clientHeight, clientWidth },
      } = ref;

      setDimensions({
        width: Math.round(clientWidth),
        height: Math.round(clientHeight),
      });
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    updateDimensions();
    return () => window.removeEventListener("resize", updateDimensions);
  }, [ref]);
  return dimensions;
};
