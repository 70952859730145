import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoginCard from "./LoginCard";
import { loginButtonStyles } from "./util";
import { isEmailValid } from "../../util";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";

interface Props {
  onInputChange: ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onInputFocus: () => void;
  email: string;
  onBackToLogin: () => void;
  onEmailInputBlur: () => void;
  onResetPasswordSubmit: () => void;
  network: { isLoading: boolean; error: boolean };
  invalidEmail: boolean;
}

const ResetPasswordForm: React.FC<Props> = ({
  onInputChange,
  onInputFocus,
  email,
  onBackToLogin,
  onEmailInputBlur,
  onResetPasswordSubmit,
  network: { isLoading, error },
  invalidEmail,
}) => {
  const isSubmitDisabled = isLoading || !email || !isEmailValid(email);

  let FormErrorMessage = AuthMessage.SendResetPasswordFailed;
  if (invalidEmail) {
    FormErrorMessage = AuthMessage.InvalidEmail;
  }

  const handleSubmit = (
    event: Event | React.SyntheticEvent<Element, Event>
  ) => {
    event.preventDefault();
    onResetPasswordSubmit();
  };

  return (
    <LoginCard>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Typography variant="h6" textAlign="center" mt={8} mb={1}>
          <strong>Forgot Password</strong>
        </Typography>
        <Typography textAlign="center" mb={8}>
          Enter your email address. If an account exists you&#x2019;ll receive a
          link to reset your password.
        </Typography>
        <Box display="flex" flexDirection="column" gap={6} width="100%">
          <TextField
            autoFocus
            onBlur={onEmailInputBlur}
            autoComplete="email"
            name="email"
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={email}
            placeholder="Email"
            type="text"
            fullWidth
            disabled={isLoading}
          />
        </Box>
        <Button
          sx={loginButtonStyles}
          onClick={onBackToLogin}
          disabled={isLoading}
        >
          Back to Sign In
        </Button>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitDisabled}
        >
          Reset Password
        </Button>
        <FormError show={error || invalidEmail} message={FormErrorMessage} />
      </form>
    </LoginCard>
  );
};

export default ResetPasswordForm;
