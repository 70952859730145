import React, { useState, useContext, useReducer, useEffect } from "react";
import { useSnackbar } from "notistack";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoneIcon from "../../icons/Zone";
import SpacesIcon from "../../icons/Spaces";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FiltrationSpaceIcon from "../../icons/FiltrationSpace";
import { isAdmin } from "../../util/permissions";
import { OccuUser, SpaceType, SpaceStatus, FloorPlan } from "../../interfaces";
import SpaceStatusIndicator from "../../components/SpaceStatusIndicator";
import ZoneStatusIndicator from "../../components/ZoneStatusIndicator";
import authContext from "../../authContext";
import ViewContext from "../../ViewContext";
import TreeContext from "../../TreeContext";
import {
  EditSpaceNameRefetchQueries,
  EditCapacityRefetchQueries,
  FetchPolicy,
} from "../../network";
import {
  ActionType,
  reducer,
  handleHourRuleDialogOpen,
  handleHourRuleDialogClose,
  INITIAL_STATE,
  getWeekdaysInRange,
  HourSpecialTabState,
  HourSpecialQueryResult,
  handleCreateHourRuleClick,
} from "../../state/hours-dialog";
import LiveData from "./LiveData";
import FloorPlans from "./FloorPlans";
import { AnyVal } from "../../interfaces";
import EditableSpaceName from "./EditableSpaceName";
import EditableCapacity from "./EditableCapacity";
import SpaceName from "./SpaceName";
import GreyBoxLabel from "./GreyBoxLabel";
import { formatNumberWithComma } from "../../util/format";
import HourRuleDialog from "../HourRuleDialog/index";
import { convertFrontEndSetHourToBackend } from "common/dates";
import OccuSnackbar from "../OccuSnackbar";
import {
  assignHourRuleError,
  assignHourRuleSuccessSubTitle,
  assignHourRuleSuccessTitle,
  createHourBreakError,
  createHourBreakSuccessSubTitle,
  createHourBreakSuccessTitle,
  createHourRuleError,
  createHourRuleSuccessSubTitle,
  createHourRuleSuccessTitle,
  createHourSpecialError,
  createHourSpecialSuccessSubTitle,
  createHourSpecialSuccessTitle,
  editHourBreakError,
  editHourBreakSuccessSubTitle,
  editHourBreakSuccessTitle,
  editHourSpecialError,
  editHourSpecialSuccessSubTitle,
  editHourSpecialSuccessTitle,
  editHourStandardError,
  editHourStandardSuccessSubTitle,
  editHourStandardSuccessTitle,
  getHourBreakByIdError,
  overlappingDateRangeError,
} from "../../util/copy";
import HoursBlock from "./HoursBlock";

interface Props {
  onError: () => void;
  spaceId: number;
}

interface SpaceNameState {
  error: boolean;
  isEditing: boolean;
  isLoading: boolean;
  nameInput: string;
}

interface CapacityState {
  error: boolean;
  isEditing: boolean;
  isLoading: boolean;
  capacityInput: string;
}

interface RightPanelData {
  capacity: number;
  lastUpdated: string;
  liveZoneTotal: number;
  name: string;
  sensorTotal: number;
  status: SpaceStatus;
  type: SpaceType;
  zoneTotal: number;
  liveData: {
    occupancy: number;
    percent: number;
  };
  floorPlans: FloorPlan[];
}

interface NetworkState {
  error: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  previousSpaceId: number | null;
  areFloorPlansLoading: boolean;
}

const GetPortalRightPanel = gql`
  query GetPortalRightPanel($spaceId: Int!) {
    GetPortalRightPanel(spaceId: $spaceId) {
      lastUpdated
      liveData {
        occupancy
        percent
      }
      floorPlans {
        createdDate
        description
        filename
        id
        name
        status
        url
      }
    }
  }
`;

const GetSpaceHourRule = gql`
  query GetSpaceHourRule($spaceId: Int!) {
    GetSpaceHourRule(spaceId: $spaceId) {
      customerId
      hourBreaks {
        disabled
        endDate
        hourRulesId
        id
        name
        startDate
      }
      hourSpecials {
        close
        day
        disabled
        hourRulesId
        id
        name
        open
      }
      hourStandards {
        hours {
          close
          open
        }
        weekday
      }
      id
      locationsUsingThisHourRule
      name
      source
    }
  }
`;

const GetCustomerHourRulesQuery = gql`
  query GetCustomerHourRules($spaceId: Int!) {
    GetCustomerHourRules(spaceId: $spaceId) {
      customerHourRules {
        customerId
        id
        name
        source
      }
      globalHourRules {
        customerId
        id
        name
        source
      }
    }
  }
`;

const GetHourBreakByIdQuery = gql`
  query GetHourBreakById($hourBreakId: Int!) {
    GetHourBreakById(hourBreakId: $hourBreakId) {
      id
      name
      hourRulesId
      startDate
      endDate
      disabled
      hourBreakStandards {
        hours {
          close
          open
        }
        weekday
      }
    }
  }
`;

const EditHourRuleMutation = gql`
  mutation EditHourRule($hourRuleInput: EditHourRuleInput!) {
    EditHourRule(hourRuleInput: $hourRuleInput) {
      void
    }
  }
`;

const EditHourSpecialMutation = gql`
  mutation EditHourSpecial($hourSpecialInput: EditHourSpecialInput!) {
    EditHourSpecial(hourSpecialInput: $hourSpecialInput) {
      void
    }
  }
`;

const EditHourBreakMutation = gql`
  mutation EditHourBreak($hourBreakInput: EditHourBreakInput!) {
    EditHourBreak(hourBreakInput: $hourBreakInput) {
      void
    }
  }
`;

const EditSpaceNameMutation = gql`
  mutation EditSpaceName($spaceId: Int!, $nameInput: String!) {
    EditSpaceName(spaceId: $spaceId, nameInput: $nameInput)
  }
`;

const EditCapacityMutation = gql`
  mutation EditZoneCapacity($spaceId: Int!, $capacityInput: Int!) {
    EditZoneCapacity(spaceId: $spaceId, capacityInput: $capacityInput)
  }
`;

const CreateHourBreakMutation = gql`
  mutation CreateHourBreak($hourBreakInput: CreateHourBreakInput!) {
    CreateHourBreak(hourBreakInput: $hourBreakInput) {
      void
    }
  }
`;

const CreateHourSpecialMutation = gql`
  mutation CreateHourSpecial($hourSpecialInput: CreateHourSpecialInput!) {
    CreateHourSpecial(hourSpecialInput: $hourSpecialInput) {
      void
    }
  }
`;

const AssignHourRuleMutation = gql`
  mutation AssignHourRule($spaceId: Int!, $hourRulesId: Int!) {
    AssignHourRule(spaceId: $spaceId, hourRulesId: $hourRulesId) {
      void
    }
  }
`;

const CreateHourRuleMutation = gql`
  mutation CreateHourRule($hourRuleInput: CreateHourRuleInput!) {
    CreateHourRule(hourRuleInput: $hourRuleInput) {
      void
    }
  }
`;

const editIconId = "space-details-edit-hours-icon";

const DEFAULT_SPACE_NAME_STATE = {
  error: false,
  isEditing: false,
  isLoading: false,
  nameInput: "",
};

const DEFAULT_CAPACITY_STATE = {
  error: false,
  isEditing: false,
  isLoading: false,
  capacityInput: "",
  previousCapacity: null,
};

const DEFAULT_NETWORK_STATE = {
  isLoading: false,
  error: false,
  data: null,
  previousSpaceId: null,
  areFloorPlansLoading: false,
};

const RightPanel: React.FC<Props> = ({ onError, spaceId }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { occuUser } = useContext(authContext);
  const { nodes } = useContext(TreeContext);
  const {
    capacity,
    totalLiveZones: liveZoneTotal,
    name: spaceName,
    spaceStatus,
    type: spaceType,
    totalZones: zoneTotal,
    zoneStatus,
    occupancy,
  } = nodes[spaceId];
  const { handleRightPanelToggle } = useContext(ViewContext);
  const userRole = (occuUser as OccuUser).role;

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {
    pageState,
    hourRuleDialog: hourRuleDialogState,
    rightPanelStandard,
  } = state;

  const [spaceNameState, setSpaceNameState] = useState<SpaceNameState>(
    DEFAULT_SPACE_NAME_STATE
  );
  const [capacityState, setCapacityState] = useState<CapacityState>(
    DEFAULT_CAPACITY_STATE
  );
  const [dataState, setDataState] = useState<NetworkState>(
    DEFAULT_NETWORK_STATE
  );

  const handleEditAttributeSuccess = () => {
    getData();
  };

  const [getRightPanelData] = useLazyQuery(GetPortalRightPanel, {
    variables: { spaceId },
    fetchPolicy: FetchPolicy.NoCache,
  });

  const [getSpaceHourRule] = useLazyQuery(GetSpaceHourRule, {
    variables: { spaceId },
    fetchPolicy: FetchPolicy.NoCache,
  });

  const [getCustomerHourRules] = useLazyQuery(GetCustomerHourRulesQuery, {
    variables: { spaceId },
    fetchPolicy: FetchPolicy.NoCache,
  });

  const [getHourBreakById] = useLazyQuery(GetHourBreakByIdQuery, {
    variables: { hourBreakId: state.hourRuleDialog.currentHourBreakId },
    fetchPolicy: FetchPolicy.NoCache,
  });

  const [editHourStandard] = useMutation(EditHourRuleMutation, {
    variables: { hourRuleInput: {} },
  });

  const [editHourSpecial] = useMutation(EditHourSpecialMutation, {
    variables: { hourSpecialInput: {} },
  });

  const [editHourBreak] = useMutation(EditHourBreakMutation, {
    variables: { hourBreakInput: {} },
  });

  const [createHourRule] = useMutation(CreateHourRuleMutation, {
    variables: { hourRuleInput: {} },
  });

  const [createHourBreak] = useMutation(CreateHourBreakMutation, {
    variables: { hourBreakInput: {} },
  });

  const [createHourSpecial] = useMutation(CreateHourSpecialMutation, {
    variables: { hourSpecialInput: {} },
  });

  const [assignHourRule] = useMutation(AssignHourRuleMutation, {
    variables: { spaceId, hourRulesId: 0 },
  });

  const [editSpaceName] = useMutation(EditSpaceNameMutation, {
    refetchQueries: EditSpaceNameRefetchQueries,
    onCompleted: handleEditAttributeSuccess,
  });

  const [editCapacity] = useMutation(EditCapacityMutation, {
    refetchQueries: EditCapacityRefetchQueries,
    onCompleted: handleEditAttributeSuccess,
  });

  useEffect(() => {
    if (state.hourRuleDialog.currentTab === 1) {
      if (state.hourRuleDialog.currentHourBreakId) {
        handleGetHourBreakByIdClick(state.hourRuleDialog.currentHourBreakId)();
        return;
      }
    }
  }, [state.hourRuleDialog.currentTab]);

  const getData = async (overrides: Partial<NetworkState> = {}) => {
    try {
      setDataState((prevState) => ({
        ...prevState,
        ...overrides,
        isLoading: true,
        error: false,
      }));
      dispatch({ type: ActionType.SpaceIdChange });
      const {
        data: { GetPortalRightPanel },
      } = await getRightPanelData();
      const {
        data: { GetSpaceHourRule },
      } = await getSpaceHourRule();
      if (GetSpaceHourRule) {
        dispatch({
          type: ActionType.PageSuccess,
          value: { data: GetSpaceHourRule },
        });
      }
      const areFloorPlansLoading =
        GetPortalRightPanel.floorPlans && GetPortalRightPanel.floorPlans.length;
      setDataState((prevState) => ({
        ...prevState,
        data: GetPortalRightPanel,
        isLoading: false,
        error: false,
        areFloorPlansLoading,
      }));
    } catch (e) {
      setDataState((prevState) => ({
        ...prevState,
        data: null,
        isLoading: false,
        error: true,
      }));
    }
  };

  const handleGetHourBreakByIdClick = (hourBreakId: number) => async () => {
    try {
      dispatch({ type: ActionType.GetBreakLoad, value: hourBreakId });
      const {
        data: { GetHourBreakById },
        error: getHourBreakError,
      } = await getHourBreakById({
        variables: { hourBreakId },
      });
      if (getHourBreakError) {
        dispatch({
          type: ActionType.GetBreakError,
          value: getHourBreakByIdError,
        });
        return;
      } else {
        dispatch({
          type: ActionType.GetBreakSuccess,
          value: GetHourBreakById,
        });
      }
    } catch (e) {
      dispatch({
        type: ActionType.GetBreakError,
        value: getHourBreakByIdError,
      });
    }
  };

  const handleAssignHourRuleOptionClick = async () => {
    try {
      dispatch({ type: ActionType.GetCustomerHourRulesLoad });
      const {
        data: { GetCustomerHourRules },
      } = await getCustomerHourRules();
      dispatch({
        type: ActionType.GetCustomerHourRulesSuccess,
        value: GetCustomerHourRules,
      });
    } catch (e) {
      dispatch({
        type: ActionType.GetCustomerHourRulesError,
        value: assignHourRuleError,
      });
    }
  };

  const handleEditStandardRuleSubmit = async () => {
    const { standardTab } = hourRuleDialogState;
    const hours = standardTab.newWeekdaysWithHours.map((weekdayWithHours) => {
      if (weekdayWithHours.isAlwaysClosed) {
        return null;
      }
      if (weekdayWithHours.isAlwaysOpen) {
        return {
          weekday: weekdayWithHours.day.id,
          hours: [
            {
              open: "00:00:00",
              close: "24:00:00",
            },
          ],
        };
      }
      return {
        weekday: weekdayWithHours.day.id,
        hours: weekdayWithHours.setHours.map((hour) =>
          convertFrontEndSetHourToBackend(hour)
        ),
      };
    });
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      await editHourStandard({
        variables: {
          hourRuleInput: {
            id: hourRuleDialogState.standardTab.hourRuleId,
            name: standardTab.ruleName,
            hourStandards: hours.filter((hour) => hour !== null),
          },
        },
      });
      const {
        data: { GetSpaceHourRule },
      } = await getSpaceHourRule();
      if (GetSpaceHourRule) {
        dispatch({
          type: ActionType.PageSuccess,
          value: { data: GetSpaceHourRule },
        });
      }
      dispatch({ type: ActionType.HourRuleDialogSuccess });
      enqueueSnackbar(editHourStandardSuccessTitle, {
        content: (key, message) => (
          <OccuSnackbar
            snackBarKey={key}
            title={message}
            subTitle={editHourStandardSuccessSubTitle}
          />
        ),
      });
    } catch (e) {
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: editHourStandardError,
      });
    }
  };

  const handleCreateHourRuleSubmit = async () => {
    const { standardTab } = hourRuleDialogState;
    const hours = standardTab.newWeekdaysWithHours.map((weekdayWithHours) => {
      if (weekdayWithHours.isAlwaysClosed) {
        return null;
      }
      if (weekdayWithHours.isAlwaysOpen) {
        return {
          weekday: weekdayWithHours.day.id,
          hours: [
            {
              open: "00:00:00",
              close: "24:00:00",
            },
          ],
        };
      }
      return {
        weekday: weekdayWithHours.day.id,
        hours: weekdayWithHours.setHours.map((hour) =>
          convertFrontEndSetHourToBackend(hour)
        ),
      };
    });
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      const { errors: createHourRuleErrors } = await createHourRule({
        variables: {
          hourRuleInput: {
            source: "",
            spaceId,
            name: standardTab.ruleName,
            hourStandards: hours.filter((hour) => hour !== null),
            assign: true,
          },
        },
      });
      if (createHourRuleErrors) {
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: createHourRuleError,
        });
        return;
      } else {
        const {
          data: { GetSpaceHourRule },
        } = await getSpaceHourRule();
        if (GetSpaceHourRule) {
          dispatch({
            type: ActionType.PageSuccess,
            value: { data: GetSpaceHourRule, isOpen: false },
          });
          dispatch({
            type: ActionType.HourRuleDialogSuccess,
            value: { isOpen: false },
          });
          enqueueSnackbar(createHourRuleSuccessTitle, {
            content: (key, message) => (
              <OccuSnackbar
                snackBarKey={key}
                title={message}
                subTitle={createHourRuleSuccessSubTitle}
              />
            ),
          });
        } else {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: "Error fetching hour data.",
          });
        }
      }
    } catch (e) {
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: createHourRuleError,
      });
    }
  };

  const handleEditHourSpecialSubmit = async () => {
    dispatch({ type: ActionType.HourRuleDialogLoad });
    const convertHourSpecialDialogState = (
      hourSpecialDialogState: HourSpecialTabState
    ): HourSpecialQueryResult => {
      const {
        hourSpecialName,
        isAllClose,
        isAllOpen,
        setHour,
        disabled,
        id,
        date,
      } = hourSpecialDialogState;
      const newSetHours = convertFrontEndSetHourToBackend(setHour);
      const hourSpecial = {
        close: isAllClose
          ? "00:00:00"
          : isAllOpen
          ? "24:00:00"
          : newSetHours.close,
        open: isAllClose || isAllOpen ? "00:00:00" : newSetHours.open,
        disabled,
        hourRulesId: pageState.data?.id as number,
        id: id as number,
        name: hourSpecialName,
        day: date ? date.toISODate() : "",
      };
      return hourSpecial;
    };
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      const { errors: editHourSpecialErrors } = await editHourSpecial({
        variables: {
          hourSpecialInput: {
            ...convertHourSpecialDialogState(
              hourRuleDialogState.specialHoursTab
            ),
          },
        },
      });
      if (editHourSpecialErrors) {
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: editHourSpecialError,
        });
        return;
      } else {
        const {
          data: { GetSpaceHourRule },
        } = await getSpaceHourRule();
        if (GetSpaceHourRule) {
          dispatch({
            type: ActionType.PageSuccess,
            value: { data: GetSpaceHourRule },
          });
          dispatch({ type: ActionType.HourRuleDialogSuccess });
          enqueueSnackbar(editHourSpecialSuccessTitle, {
            content: (key, message) => (
              <OccuSnackbar
                snackBarKey={key}
                title={message}
                subTitle={editHourSpecialSuccessSubTitle}
              />
            ),
          });
        } else {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: "Error fetching hour data.",
          });
        }
      }
    } catch (e) {
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: editHourSpecialError,
      });
    }
  };

  const handleCreateHourSpecialSubmit = async () => {
    dispatch({ type: ActionType.HourRuleDialogLoad });
    const convertHourSpecialDialogState = (
      hourSpecialDialogState: HourSpecialTabState
    ) => {
      const { hourSpecialName, isAllClose, isAllOpen, setHour, date } =
        hourSpecialDialogState;
      const newSetHours = convertFrontEndSetHourToBackend(setHour);
      const hourSpecial = {
        close: isAllClose
          ? "00:00:00"
          : isAllOpen
          ? "24:00:00"
          : newSetHours.close,
        open: isAllClose || isAllOpen ? "00:00:00" : newSetHours.open,
        spaceId,
        disabled: false,
        hourRulesId: pageState.data?.id as number,
        name: hourSpecialName,
        day: date ? date.toISODate() : "",
      };
      return hourSpecial;
    };
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      const { errors: createHourSpecialErrors } = await createHourSpecial({
        variables: {
          hourSpecialInput: {
            ...convertHourSpecialDialogState(
              hourRuleDialogState.specialHoursTab
            ),
          },
        },
      });
      if (createHourSpecialErrors) {
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: createHourSpecialError,
        });
        return;
      } else {
        const {
          data: { GetSpaceHourRule },
        } = await getSpaceHourRule();
        if (GetSpaceHourRule) {
          dispatch({
            type: ActionType.PageSuccess,
            value: { data: GetSpaceHourRule, isOpen: true },
          });
          dispatch({
            type: ActionType.HourRuleDialogSuccess,
            value: { isOpen: true },
          });
          enqueueSnackbar(createHourSpecialSuccessTitle, {
            content: (key, message) => (
              <OccuSnackbar
                snackBarKey={key}
                title={message}
                subTitle={createHourSpecialSuccessSubTitle}
              />
            ),
          });
        } else {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: "Error fetching hour data.",
          });
        }
      }
    } catch (e) {
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: createHourSpecialError,
      });
    }
  };

  const handleEditHourBreakSubmit = async () => {
    const { breakHoursTab } = hourRuleDialogState;
    const { dateRange, breakName, id, disabled, newWeekdaysWithHours } =
      breakHoursTab;

    const weekdaysInRange = getWeekdaysInRange(dateRange);
    const hours = newWeekdaysWithHours.map((weekdayWithHours) => {
      if (!weekdaysInRange.includes(weekdayWithHours.day.id)) {
        return null;
      }
      if (weekdayWithHours.isAlwaysClosed) {
        return null;
      }
      if (weekdayWithHours.isAlwaysOpen) {
        return {
          weekday: weekdayWithHours.day.id,
          hours: [
            {
              open: "00:00:00",
              close: "24:00:00",
            },
          ],
        };
      }
      return {
        weekday: weekdayWithHours.day.id,
        hours: weekdayWithHours.setHours.map((hour) =>
          convertFrontEndSetHourToBackend(hour)
        ),
      };
    });
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      const { errors: editHourBreakErrors } = await editHourBreak({
        variables: {
          hourBreakInput: {
            id,
            name: breakName,
            hourRulesId: hourRuleDialogState.standardTab.hourRuleId,
            disabled,
            hourBreakStandards: hours.filter((hour) => hour !== null),
            startDate: dateRange[0]?.toISODate(),
            endDate: dateRange[1]?.toISODate(),
          },
        },
      });
      if (editHourBreakErrors) {
        const message =
          editHourBreakErrors[0].message === "DateOverlap"
            ? overlappingDateRangeError
            : editHourBreakError;
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: message,
        });
        return;
      } else {
        const {
          data: { GetSpaceHourRule },
        } = await getSpaceHourRule();
        if (GetSpaceHourRule) {
          dispatch({
            type: ActionType.PageSuccess,
            value: { data: GetSpaceHourRule },
          });
          dispatch({ type: ActionType.HourRuleDialogSuccess });
          enqueueSnackbar(editHourBreakSuccessTitle, {
            content: (key, message) => (
              <OccuSnackbar
                snackBarKey={key}
                title={message}
                subTitle={editHourBreakSuccessSubTitle}
              />
            ),
          });
        } else {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: "Error fetching hour data.",
          });
        }
      }
    } catch (e) {
      const message =
        (e as AnyVal)?.message && (e as AnyVal)?.message === "DateOverlap"
          ? overlappingDateRangeError
          : editHourBreakError;
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: message,
      });
    }
  };

  const handleCreateHourBreakSubmit = async () => {
    const { breakHoursTab } = hourRuleDialogState;
    const { dateRange, breakName, newWeekdaysWithHours } = breakHoursTab;

    const weekdaysInRange = getWeekdaysInRange(dateRange);
    const hours = newWeekdaysWithHours.map((weekdayWithHours) => {
      if (!weekdaysInRange.includes(weekdayWithHours.day.id)) {
        return null;
      }
      if (weekdayWithHours.isAlwaysClosed) {
        return null;
      }
      if (weekdayWithHours.isAlwaysOpen) {
        return {
          weekday: weekdayWithHours.day.id,
          hours: [
            {
              open: "00:00:00",
              close: "24:00:00",
            },
          ],
        };
      }
      return {
        weekday: weekdayWithHours.day.id,
        hours: weekdayWithHours.setHours.map((hour) =>
          convertFrontEndSetHourToBackend(hour)
        ),
      };
    });
    try {
      dispatch({ type: ActionType.HourRuleDialogLoad });
      const { errors: createHourBreakErrors } = await createHourBreak({
        variables: {
          hourBreakInput: {
            name: breakName,
            hourRulesId: hourRuleDialogState.standardTab.hourRuleId,
            spaceId,
            disabled: false,
            hourBreakStandards: hours.filter((hour) => hour !== null),
            startDate: dateRange[0]?.toISODate(),
            endDate: dateRange[1]?.toISODate(),
          },
        },
      });
      if (createHourBreakErrors) {
        const message =
          createHourBreakErrors[0].message === "DateOverlap"
            ? overlappingDateRangeError
            : createHourBreakError;
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: message,
        });
        return;
      } else {
        const {
          data: { GetSpaceHourRule },
        } = await getSpaceHourRule();
        if (GetSpaceHourRule) {
          dispatch({
            type: ActionType.PageSuccess,
            value: { data: GetSpaceHourRule, value: { isOpen: true } },
          });
          dispatch({
            type: ActionType.HourRuleDialogSuccess,
            value: { isOpen: true },
          });
          enqueueSnackbar(createHourBreakSuccessTitle, {
            content: (key, message) => (
              <OccuSnackbar
                snackBarKey={key}
                title={message}
                subTitle={createHourBreakSuccessSubTitle}
              />
            ),
          });
        } else {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: "Error fetching hour data.",
          });
        }
      }
    } catch (e) {
      const message =
        (e as AnyVal)?.message && (e as AnyVal)?.message === "DateOverlap"
          ? overlappingDateRangeError
          : createHourBreakError;
      dispatch({
        type: ActionType.HourRuleDialogError,
        value: message,
      });
    }
  };

  const handleAssignHourRuleSubmit =
    (hourRulesId: number | null) => async () => {
      if (hourRulesId === null) {
        return;
      }
      try {
        dispatch({ type: ActionType.HourRuleDialogLoad });
        const { errors: assignHourRuleErrors } = await assignHourRule({
          variables: { spaceId, hourRulesId },
        });
        if (assignHourRuleErrors) {
          dispatch({
            type: ActionType.HourRuleDialogError,
            value: assignHourRuleError,
          });
          return;
        } else {
          const {
            data: { GetSpaceHourRule },
          } = await getSpaceHourRule();
          if (GetSpaceHourRule) {
            dispatch({
              type: ActionType.PageSuccess,
              value: { data: GetSpaceHourRule, isOpen: false },
            });
            dispatch({
              type: ActionType.HourRuleDialogSuccess,
              value: { isOpen: false },
            });
            enqueueSnackbar(assignHourRuleSuccessTitle, {
              content: (key, message) => (
                <OccuSnackbar
                  snackBarKey={key}
                  title={message}
                  subTitle={assignHourRuleSuccessSubTitle}
                />
              ),
            });
          } else {
            dispatch({
              type: ActionType.HourRuleDialogError,
              value: assignHourRuleError,
            });
          }
        }
      } catch (e) {
        dispatch({
          type: ActionType.HourRuleDialogError,
          value: assignHourRuleError,
        });
      }
    };

  if (spaceId != dataState.previousSpaceId) {
    getData({ previousSpaceId: spaceId });
  }

  const opacity =
    dataState.isLoading || dataState.areFloorPlansLoading ? "0.5" : 1;

  const Wrapper = ({ children }: { children: React.ReactNode[] | null }) => {
    return (
      <Box sx={{ opacity }} minWidth={0}>
        {children}
      </Box>
    );
  };

  if (dataState.data === null) {
    // TODO: loading and error states
    return <Wrapper>{null}</Wrapper>;
  }

  const { lastUpdated, liveData, floorPlans } =
    dataState.data as RightPanelData;

  const handleEditSpaceNameSubmit = () => {
    (async () => {
      try {
        const { nameInput } = spaceNameState;
        setSpaceNameState({
          ...spaceNameState,
          isEditing: true,
          isLoading: true,
        });
        await editSpaceName({ variables: { spaceId, nameInput } });
        setSpaceNameState({
          ...spaceNameState,
          nameInput: "",
          isEditing: false,
          isLoading: false,
        });
      } catch (e) {
        setSpaceNameState({
          ...spaceNameState,
          isLoading: false,
          error: true,
        });
      }
    })();
  };

  const handleEditCapacitySubmit = () => {
    (async () => {
      try {
        const { capacityInput } = capacityState;
        if (capacityInput === "" || capacityInput === "0") {
          return;
        }
        setCapacityState({
          ...capacityState,
          error: false,
          isEditing: true,
          isLoading: true,
        });
        await editCapacity({ variables: { spaceId, capacityInput } });
        setCapacityState({
          ...capacityState,
          capacityInput: "",
          isEditing: false,
          isLoading: false,
        });
      } catch (e) {
        setCapacityState({
          ...capacityState,
          isLoading: false,
          error: true,
        });
      }
    })();
  };

  const handleSpaceNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSpaceNameState({ ...spaceNameState, nameInput: value });
  };

  const handleCapacityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCapacityState({ ...capacityState, capacityInput: value });
  };

  const handleEditNameClick = () => {
    setSpaceNameState({
      isLoading: false,
      error: false,
      nameInput: spaceName,
      isEditing: true,
    });
  };

  const handleEditNameCancelClick = () => {
    setSpaceNameState({ ...spaceNameState, error: false, isEditing: false });
  };

  const handleEditCapacityClick = () => {
    setCapacityState({
      error: false,
      capacityInput: String(capacity),
      isEditing: true,
      isLoading: false,
    });
  };

  const handleEditCapacityCancelClick = () => {
    setCapacityState({ ...capacityState, isEditing: false, error: false });
  };

  const handleFloorPlanImageRender = () => {
    setDataState((prevState) => ({
      ...prevState,
      areFloorPlansLoading: false,
    }));
  };

  if (dataState.error) {
    onError();
    return null;
  }

  const SpaceTypeIconLabel = () => {
    switch (spaceType) {
      case SpaceType.Zone:
        return (
          <>
            <ZoneIcon />
            <Typography px={2} noWrap>
              Zone
            </Typography>
          </>
        );
      case SpaceType.Group:
        return (
          <>
            <SpacesIcon />
            <Typography px={2} noWrap>
              Space Group
            </Typography>
          </>
        );
      case SpaceType.Filtration:
        return (
          <>
            <FiltrationSpaceIcon />
            <Typography px={2} noWrap>
              Filtration Space
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  const renderCapacity = () => {
    if (spaceType !== SpaceType.Zone || !isAdmin(userRole)) {
      let displayCapacity =
        spaceType === SpaceType.Filtration
          ? "-"
          : formatNumberWithComma(capacity);

      if (
        spaceStatus == SpaceStatus.Inactive &&
        spaceType !== SpaceType.Filtration
      ) {
        displayCapacity = displayCapacity + " (Inactive)";
      }

      return (
        <Box>
          <Typography variant="label" gutterBottom>
            Capacity
          </Typography>
          <Typography>{displayCapacity}</Typography>
        </Box>
      );
    }

    const { isEditing, isLoading, error, capacityInput } = capacityState;

    let capacityValue = capacity.toString();
    if (spaceStatus == SpaceStatus.Inactive) {
      capacityValue = capacityValue + " (Inactive)";
    }

    return (
      <EditableCapacity
        isEditing={isEditing}
        isLoading={isLoading}
        onEdit={handleEditCapacityClick}
        onCancel={handleEditCapacityCancelClick}
        onSubmit={handleEditCapacitySubmit}
        capacity={capacityValue}
        error={error}
        capacityInput={capacityInput}
        onChange={handleCapacityChange}
        hasChanged={capacity != Number.parseInt(capacityInput)}
      />
    );
  };

  const renderHours = () => {
    if (
      rightPanelStandard.length === 0 ||
      !state.pageState.data?.hourStandards
    ) {
      return null;
    }

    if (!isAdmin(userRole)) {
      return (
        <Box>
          <GreyBoxLabel title="Hours" />
          <HoursBlock weekdaysWithHours={rightPanelStandard} />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          // When hovering on the box, make edit icon visible
          [`&:hover #${editIconId}`]: {
            visibility: "visible",
            opacity: 1,
            transition: `visibility 0s linear 0s, opacity ${theme.transitions.duration.shorter}ms`,
          },
        }}
      >
        <GreyBoxLabel title="Hours" />
        <Box>
          <HoursBlock weekdaysWithHours={rightPanelStandard} />
          <Box display="flex" justifyContent="end">
            <IconButton
              size="small"
              onClick={
                state.pageState.data?.customerId === 1
                  ? handleCreateHourRuleClick(dispatch)
                  : handleHourRuleDialogOpen(dispatch)
              }
              id={editIconId}
              sx={{ visibility: "hidden", opacity: 0 }}
            >
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        <HourRuleDialog
          dispatch={dispatch}
          dialogState={hourRuleDialogState}
          data={pageState.data}
          onClose={handleHourRuleDialogClose(dispatch)}
          onGetHourBreakByIdClick={handleGetHourBreakByIdClick}
          onEditStandardRuleSubmit={handleEditStandardRuleSubmit}
          onEditHourSpecialSubmit={handleEditHourSpecialSubmit}
          onEditHourBreakSubmit={handleEditHourBreakSubmit}
          onAssignHourRuleOptionClick={handleAssignHourRuleOptionClick}
          onCreateHourBreakSubmit={handleCreateHourBreakSubmit}
          onCreateHourSpecialSubmit={handleCreateHourSpecialSubmit}
          onAssignHourRuleSubmit={handleAssignHourRuleSubmit}
          onCreateHourRuleSubmit={handleCreateHourRuleSubmit}
        />
      </Box>
    );
  };

  const renderName = () => {
    if (!isAdmin(userRole)) {
      return <SpaceName name={spaceName} />;
    }

    const { isEditing, isLoading, error, nameInput } = spaceNameState;

    return (
      <EditableSpaceName
        isEditing={isEditing}
        isLoading={isLoading}
        onEdit={handleEditNameClick}
        onCancel={handleEditNameCancelClick}
        onSubmit={handleEditSpaceNameSubmit}
        name={spaceName}
        error={error}
        nameInput={nameInput}
        onChange={handleSpaceNameChange}
      />
    );
  };

  return (
    <Box
      sx={{ opacity, overflow: "auto", height: "100%" }}
      minWidth={0}
      px={2}
      pb={4}
      display="flex"
      flexDirection="column"
      gap={3}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={4}
        pb={1}
        bgcolor="white"
        minWidth={0}
      >
        <Typography noWrap>
          <strong>{spaceName} Details</strong>
        </Typography>
        <IconButton size="small" onClick={handleRightPanelToggle}>
          <CloseIcon fontSize="inherit" sx={{ color: "common.black" }} />
        </IconButton>
      </Box>
      {spaceStatus == SpaceStatus.Live && occupancy && (
        <LiveData
          lastUpdated={lastUpdated}
          occupancy={liveData.occupancy}
          percent={liveData.percent}
        />
      )}
      <GreyBoxLabel title="Attributes" />
      <Box display="flex" flexDirection="column" gap={8}>
        {renderName()}
        <Box>
          <Typography variant="label" gutterBottom>
            Type
          </Typography>
          <Box display="flex" alignItems="center">
            <SpaceTypeIconLabel />
          </Box>
        </Box>
        <Box>
          <Typography variant="label" gutterBottom>
            Status
          </Typography>
          <Box display="flex" alignItems="center">
            {spaceType === SpaceType.Group && (
              <SpaceStatusIndicator
                status={spaceStatus}
                liveCount={liveZoneTotal}
                liveTotal={zoneTotal}
              />
            )}
            {spaceType === SpaceType.Zone && (
              <ZoneStatusIndicator status={zoneStatus} />
            )}
          </Box>
        </Box>
        {renderCapacity()}
      </Box>
      {floorPlans && floorPlans.length >= 1 && (
        <FloorPlans
          onImgRender={handleFloorPlanImageRender}
          space={{ name: spaceName, id: spaceId, floorPlans }}
        />
      )}
      {renderHours()}
    </Box>
  );
};

export default RightPanel;
