import React, { useState, useEffect } from "react";
import LoadingCircle from "../../components/LoadingCircle";

export type ImageOnLoad = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => void;

interface Props {
  children: React.ReactElement | React.ReactFragment;
  src: string;
  onLoad?: ImageOnLoad;
}

const ImageLoader: React.FC<Props> = ({ src, children, onLoad }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.onload = () => {
      const { height, width } = imageToLoad;
      // When image is loaded set loading to false
      setLoading(false);
      // Pass image dimensions after load
      if (onLoad) {
        onLoad({ height, width });
      }
    };
    imageToLoad.src = src;
  }, [src]);

  if (loading) {
    return <LoadingCircle mt={0} p={0} />;
  }

  return <>{children}</>;
};

export default ImageLoader;
