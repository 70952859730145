export function errorHandler(
  error: Error,
  info: { componentStack: string }
): void {
  if (
    process.env.NODE_ENV !== "production" ||
    process.env.IS_STAGING === "true"
  ) {
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log(error.stack);
    // eslint-disable-next-line no-console
    console.log(info?.componentStack);
  }
}
