import React from "react";
import Box from "@mui/material/Box";
import ImageLoader from "../ImageLoader";

interface Props {
  name: string;
  url: string;
  isSelected: boolean;
  onClick: () => void;
}

const Thumbnail: React.FC<Props> = ({ name, url, isSelected, onClick }) => {
  let borderColor = "grey.700";
  let boxShadow = "";
  if (isSelected) {
    borderColor = "primary.main";
    boxShadow = "0 0 3px 2px rgb(79 70 229 / 50%)";
  }

  return (
    <ImageLoader src={url}>
      <Box width={70} height={50}>
        <Box
          key={`${name}-thumbnail`}
          component="img"
          height={50}
          width="100%"
          src={url}
          alt={name}
          onClick={onClick}
          sx={{ objectFit: "contain", "&:hover": { cursor: "pointer" } }}
          border={1}
          borderColor={borderColor}
          boxShadow={boxShadow}
        />
      </Box>
    </ImageLoader>
  );
};

export default Thumbnail;
