import React, { RefObject, useState, useRef, useEffect } from "react";
import ReactImageMagnify from "react-image-magnify";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ImageLoader from "../ImageLoader";
import ThumbnailList from "../ThumbnailList";
import { useRefDimensions } from "../../hooks/useRefDimensions";
import { Image } from "../../interfaces";
import EmptyStateCardContent from "../EmptyStateCardContent";
import EmptyStateIcon from "../EmptyStateIcon";

interface Props {
  images: Image[];
  enlargedImageContainerEl: {
    id: string;
    ref: RefObject<HTMLDivElement>;
  };
  selectedImageIndex: number;
  onThumbnailClick: (index: number) => () => void;
}

interface Dimensions {
  width: number;
  height: number;
}

interface State {
  largeImage: Dimensions;
  smallImage: { isFluidWidth: boolean; height?: number; width?: number };
  loaded: boolean;
  imageResolution: Dimensions;
  pauseEnlargedImage: boolean;
}

const defaultLargeImageResolution = {
  height: 1800,
  width: 2400,
};

const defaultLargeImageWidth = 2500;

export const EnlargedImageEmptyState = ({ url }: { url: string }) => {
  return (
    <Box
      position="absolute"
      m="auto"
      left={0}
      right={0}
      top={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: `url(${url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></Box>
    </Box>
  );
};

const ImageMagnifier: React.FC<Props> = ({
  images,
  enlargedImageContainerEl: { ref, id },
  selectedImageIndex,
  onThumbnailClick,
}) => {
  const [state, setState] = useState<State>({
    largeImage: defaultLargeImageResolution,
    smallImage: { isFluidWidth: false, width: 0, height: 0 },
    loaded: false,
    imageResolution: { width: 0, height: 0 },
    pauseEnlargedImage: false,
  });
  const {
    largeImage,
    smallImage,
    loaded,
    imageResolution,
    pauseEnlargedImage,
  } = state;
  const { name, url: src } = images[selectedImageIndex];
  const alt = `${name} floor plan`;

  const containerRef = useRef<HTMLElement>(null);
  const nonImageContent = useRef<HTMLElement>(null);
  const enlargedImageElDimensions = useRefDimensions(ref);

  useEffect(() => {
    if (!containerRef.current || !nonImageContent.current) {
      return;
    }
    const { clientHeight: noneImageHeight } = nonImageContent.current;
    const { clientHeight: height, clientWidth: width } = containerRef.current;
    const imageWidthRatio = imageResolution.height / imageResolution.width;
    const imageHeightRatio = imageResolution.width / imageResolution.height;
    // We determine the width of the image based on the height of the box
    const smallImageWidth = imageHeightRatio * (height - noneImageHeight);
    const smallImageHeight = imageWidthRatio * width;

    // If the width of the image is larger than the width of the box
    // we use the width of the box to find the height
    const smallDimensions =
      smallImageWidth >= width
        ? {
            height: smallImageHeight - 1,
            width: width - 1,
          }
        : {
            height: height - noneImageHeight - 1,
            width: smallImageWidth - 1,
          };

    setState((prevState) => ({
      ...prevState,
      smallImage: {
        ...prevState.smallImage,
        ...smallDimensions,
      },
    }));
  }, [containerRef?.current, loaded, enlargedImageElDimensions]);

  const handleImageLoad = ({
    height,
    width,
  }: {
    height: number;
    width: number;
  }) => {
    // From base image create standard zoom resolution for large image
    const ratio = height / width;
    const largeImageHeight = ratio * defaultLargeImageWidth;
    setState((prevState) => ({
      ...prevState,
      largeImage: { height: largeImageHeight, width: defaultLargeImageWidth },
      loaded: true,
      imageResolution: { height, width },
    }));
    return;
  };

  const handlePauseEnlargedImage = () => {
    setState((prevState) => ({
      ...prevState,
      pauseEnlargedImage: !prevState.pauseEnlargedImage,
    }));
  };

  return (
    <Box
      flex={1}
      ref={containerRef}
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        "& > div": {
          "& img": { border: 1, borderColor: "grey.300" },
        },
      }}
    >
      <ImageLoader src={src} onLoad={handleImageLoad}>
        <Box
          onClick={handlePauseEnlargedImage}
          height={smallImage.height}
          sx={{
            "& > div:first-of-type": {
              display: pauseEnlargedImage ? "none" : "block",
            },
          }}
        >
          <ReactImageMagnify
            smallImage={{ alt, src, ...smallImage }}
            largeImage={{ src, ...largeImage }}
            enlargedImageContainerDimensions={enlargedImageElDimensions}
            enlargedImagePortalId={id}
            fadeDurationInMs={pauseEnlargedImage ? 60 * 60 * 24 * 1000 : 300}
          />
          {pauseEnlargedImage && (
            <Box
              height={smallImage.height}
              width={smallImage.width}
              sx={{
                bgcolor: "grey.300",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              alignItems="center"
              display="flex"
            >
              <EmptyStateCardContent
                text="Image Zoom Paused"
                Icon={<EmptyStateIcon Icon={ZoomInIcon} />}
                subtitle="Click to resume"
              />
            </Box>
          )}
        </Box>
        <Box
          ref={nonImageContent}
          display="flex"
          flexDirection="column"
          width="100%"
          gap={4}
          pt={4}
        >
          <Box>
            <Typography textAlign="center" gutterBottom>
              Roll over image to zoom in
            </Typography>
            <Typography textAlign="center">
              Click image to {pauseEnlargedImage ? "resume" : "pause"} zoom
            </Typography>
          </Box>
          <Divider sx={{ flex: 1 }} />
          <Box sx={{ overflowY: "auto", height: 80 }}>
            <ThumbnailList
              selectedIndex={selectedImageIndex}
              images={images}
              onClick={onThumbnailClick}
            />
          </Box>
        </Box>
      </ImageLoader>
    </Box>
  );
};

export default ImageMagnifier;
