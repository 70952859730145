import {
  newTracker,
  trackPageView,
  BrowserTracker,
  enableActivityTracking,
} from "@snowplow/browser-tracker";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const snowplowURL = process.env.SNOWPLOW_COLLECTOR_URI || "";
const endpoint = `https://${snowplowURL}`;

const useLocationChange = () => {
  const location = useLocation();
  React.useEffect(() => {
    trackPageView();
  }, [location]);
};

const useInitializeTracker = () => {
  const [tracker, setTracker] = useState<BrowserTracker | undefined>(undefined);
  const initializeTracker = () => {
    setTracker(
      newTracker("occ", endpoint, {
        connectionTimeout: 10000,
        appId: "occu-portal",
        discoverRootDomain: true,
        cookieSameSite: "Lax",
        cookieName: "occu-portal-t",
        contexts: {
          webPage: true
        },
      })
    );
    enableActivityTracking({
      minimumVisitLength: 5,
      heartbeatDelay: 5,
    });
  };
  useEffect(() => {
    if (!tracker) {
      initializeTracker();
    }
  }, [tracker]);
  return { tracker };
};

export { useLocationChange, useInitializeTracker };
