import React from "react";
import TextField from "@mui/material/TextField";
import EditableAttributeValue from "../../components/EditableAttributeValue";

interface Props {
  isEditing: boolean;
  isLoading: boolean;
  error: boolean;
  name: string;
  nameInput: string;
  onCancel: () => void;
  onEdit: () => void;
  onChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
}

const EditableSpaceName: React.FC<Props> = ({
  isLoading,
  isEditing,
  error,
  name,
  nameInput,
  onCancel,
  onEdit,
  onChange,
  onSubmit,
}) => {
  const errorMessage = error
    ? "Error trying to edit space name. Try again or contact us."
    : "";
  const isSubmitDisabled = !nameInput || isLoading || nameInput === name;

  return (
    <EditableAttributeValue
      isEditing={isEditing}
      isLoading={isLoading}
      input={
        <TextField
          fullWidth
          value={nameInput}
          onChange={onChange}
          disabled={isLoading}
        />
      }
      errorMessage={errorMessage}
      isSubmitDisabled={isSubmitDisabled}
      onEditClick={onEdit}
      onEditCancelClick={onCancel}
      onSubmitClick={onSubmit}
      value={name}
      title="Name"
    />
  );
};

export default EditableSpaceName;
