import React from "react";
import TextField from "@mui/material/TextField";
import EditableAttributeValue from "../../components/EditableAttributeValue";

interface Props {
  isEditing: boolean;
  isLoading: boolean;
  error: boolean;
  capacity: string;
  capacityInput: string;
  onCancel: () => void;
  onEdit: () => void;
  onChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  hasChanged: boolean;
}

const EditableCapacity: React.FC<Props> = ({
  isLoading,
  isEditing,
  error,
  capacity,
  capacityInput,
  onCancel,
  onEdit,
  onChange,
  onSubmit,
  hasChanged,
}) => {
  const errorMessage = error
    ? "Error trying to edit capacity. Try again or contact us."
    : "";
  const isSubmitDisabled =
    !capacityInput || isLoading || !hasChanged || Number(capacityInput) === 0;

  return (
    <EditableAttributeValue
      isEditing={isEditing}
      isLoading={isLoading}
      input={
        <TextField
          type="number"
          fullWidth
          value={capacityInput}
          onChange={onChange}
          disabled={isLoading}
        />
      }
      errorMessage={errorMessage}
      isSubmitDisabled={isSubmitDisabled}
      onEditClick={onEdit}
      onEditCancelClick={onCancel}
      onSubmitClick={onSubmit}
      value={capacity}
      title="Capacity"
    />
  );
};

export default EditableCapacity;
