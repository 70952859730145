export function getCurrentFirstPath(pathname: string): string {
  const splitPathname = pathname.split("/");
  const firstPath = splitPathname.length > 0 ? splitPathname[1] : "";

  return firstPath;
}

export function deriveSpaceIdFromPath(pathname: string): number | null {
  const splitPathname = pathname.split("/");
  const spaceIdString = splitPathname.length >= 2 ? splitPathname[2] : null;
  const spaceId = Number.parseInt(spaceIdString as string);
  return spaceId ? spaceId : null;
}

export function getCurrentSpacesTabRoute(splitPathname: string[]): SpaceRoutes {
  // Navigation between spaces
  if (splitPathname.length === 4) {
    return splitPathname[3] as SpaceRoutes;
  }
  // Navigation from root to a space
  if (splitPathname[1] === AppRoutes.Spaces && splitPathname.length === 3) {
    return splitPathname[2] as SpaceRoutes;
  }
  // Navigation from approute outside of spaces
  return SpaceRoutes.Overview;
}

export function getCurrentAnalyticsTabRoute(
  splitPathname: string[]
): AnalyticsRoute {
  // Navigation between spaces
  if (splitPathname.length === 4) {
    return splitPathname[3] as AnalyticsRoute;
  }
  // Navigation from approute outside of spaces
  return AnalyticsRoute.Overview;
}

export enum AppRoutes {
  Analytics = "analytics",
  Login = "login",
  LiveData = "live-data",
  Spaces = "spaces",
  UserManagement = "user-management",
  Users = "users",
  MyAccount = "my-account",
  Register = "register",
  ResetPassword = "reset-password",
}

export enum SpaceRoutes {
  Overview = "overview",
  Install = "install",
  Headcount = "head-count",
  Hours = "hours",
  WiFiCreds = "wifi",
  Signage = "digital-signage",
}

export enum AnalyticsRoute {
  Overview = "overview",
  Occupancy = "occupancy",
  Visitors = "visitors",
  Dwelltime = "dwell-time",
}

export interface Params {
  spaceId?: string;
  selectedTab?: string;
  firstParam?: string;
}

export enum PageTitles {
  Analytics = "Analytics",
  LiveData = "Live Data",
  Spaces = "Spaces",
  UserManagement = "User Management",
  MyAccount = "My Account",
}

export const spacesRoutes = new Set([
  AppRoutes.Spaces,
  AppRoutes.Analytics,
  AppRoutes.LiveData,
]);

export const nonSpacesRoutes = new Set([
  AppRoutes.UserManagement,
  AppRoutes.Users,
  AppRoutes.MyAccount,
]);

export const deriveSpaceId = (
  firstPath: string,
  splitPathname: string[]
): number | null => {
  if (!spacesRoutes.has(firstPath as AppRoutes) || splitPathname.length < 3) {
    return null;
  }

  const spaceId = Number.parseInt(splitPathname[2]);

  if (Number.isNaN(spaceId)) {
    return null;
  }

  return spaceId;
};
