import React, { createRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DialogHeader from "../../components/DialogHeader";
import { Image } from "../../interfaces";
import ImageMagnifier, { EnlargedImageEmptyState } from "../ImageMagnifier";
import SpaceBreadCrumbsById from "../SpaceBreadcrumbsById";
import { Space } from "../../interfaces/space";

interface Props {
  onClose: () => void;
  space: Space;
}

const enlargedFloorPlanElId = "dialogEnlargedFloorPlanId";

const FloorPlanDialog: React.FC<Props> = ({
  onClose,
  space: { name: spaceName, id: spaceId, floorPlans },
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const enlargedImageElMetadata = {
    id: enlargedFloorPlanElId,
    ref: createRef<HTMLDivElement>(),
  };
  const title = `${spaceName} Floor Plan`;

  const handleThumbnailClick = (index: number) => () => {
    setSelectedImageIndex(index);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby={`${title} Dialog`}
      open
      fullWidth
      maxWidth="xxl"
      PaperProps={{ sx: { height: "100%" } }}
    >
      <DialogHeader
        title={
          <Box display="flex" gap={2} alignItems="end">
            <Typography variant="h6">Floor Plans</Typography>
            <SpaceBreadCrumbsById
              spaceId={spaceId as number}
              includeSelf
              hideCustomerName
            />
          </Box>
        }
        onClose={onClose}
      />
      <Divider sx={{ mx: -6, mt: 3 }} />
      <DialogContent
        sx={{
          px: 0,
          display: "flex",
          gap: 4,
        }}
      >
        <Box
          flex={3}
          {...enlargedImageElMetadata}
          border={1}
          borderColor="grey.300"
          position="relative"
        >
          <EnlargedImageEmptyState
            url={(floorPlans as Image[])[selectedImageIndex].url}
          />
        </Box>
        <Box flex={1}>
          <ImageMagnifier
            images={floorPlans as Image[]}
            enlargedImageContainerEl={enlargedImageElMetadata}
            selectedImageIndex={selectedImageIndex}
            onThumbnailClick={handleThumbnailClick}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FloorPlanDialog;
