import React, { useContext } from "react";
import { Theme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import SettingsIcon from "../../icons/Settings";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import HorizontalLogoIcon from "../../icons/HorizontalLogo";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthContext from "../../authContext";
import { OccuUser, Products } from "../../interfaces";
import { AppRoutes } from "../../routing";
import { isAdmin, isSetupRole } from "../../util/permissions";
import { darkBlack } from "../../theme";
import TreeContext from "../../TreeContext";

interface Props {
  logoutOnly: boolean;
  onNavigationClick: (route: AppRoutes) => void;
}

const Appbar: React.FC<Props> = ({ logoutOnly, onNavigationClick }) => {
  const authContext = useContext(AuthContext);
  const { products, hasNoSpaces } = useContext(TreeContext);
  const hasOccupancy = products.includes(Products.Occupancy);
  const { pathname } = useLocation();
  const userInitials =
    (authContext.occuUser as OccuUser)?.firstName.charAt(0).toUpperCase() +
    (authContext.occuUser as OccuUser)?.lastName.charAt(0).toUpperCase();
  const userRole = (authContext.occuUser as OccuUser)?.role;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const splitPath = pathname.split("/");
  const firstPath = splitPath.length > 0 ? splitPath[1] : "";

  const handleNavigationClick = (route: AppRoutes) => () => {
    onNavigationClick(route);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogoutClick = async () => {
    setAnchorElUser(null);
    try {
      await authContext.signout();
    } catch (e) {
      throw "failed to signout";
    }
  };

  const handleMyAccountClick = () => {
    onNavigationClick(AppRoutes.MyAccount);
    setAnchorElUser(null);
  };

  const headerButtonStyles = {
    textDecoration: "none",
    bgcolor: "transparent",
    mx: 3,
    px: 3,
    py: 2,
    fontSize: 16,
    color: "transparent",
    "::before": {
      color: "grey.300",
      content: "attr(link-title)",
      position: "absolute",
    },
    "&:hover": {
      bgcolor: "rgba(255, 255, 255, 0.2)",
      "::before": {
        color: "white",
      },
    },
  };

  const activeButtonStyles = {
    ...headerButtonStyles,
    "::before": {
      color: "white",
      fontWeight: "700",
      content: "attr(link-title)",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const avatarStyles = {
    p: 0,
    bgcolor: "grey.100",
    width: 32,
    height: 32,
    borderRadius: 32,
    color: "red",
    "&:hover": {
      background: (theme: Theme) => theme.palette.grey[500],
    },
  };

  const linkStyles = (link: AppRoutes, secondLink?: AppRoutes) => {
    if (firstPath === link || firstPath === secondLink) {
      return activeButtonStyles;
    }
    return headerButtonStyles;
  };

  const renderUserManagementLink = () => {
    if (logoutOnly || !isAdmin(userRole)) {
      return null;
    }
    return (
      <Button
        onClick={handleNavigationClick(AppRoutes.UserManagement)}
        sx={linkStyles(AppRoutes.UserManagement, AppRoutes.Users)}
        link-title="User Management"
      >
        User Management
      </Button>
    );
  };

  return (
    <AppBar position="static" elevation={0}>
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ backgroundColor: darkBlack }}>
          <Box mr={3} display="flex">
            <HorizontalLogoIcon />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {!logoutOnly && !isSetupRole(userRole) && !hasNoSpaces && (
              <Button
                onClick={handleNavigationClick(AppRoutes.Analytics)}
                sx={linkStyles(AppRoutes.Analytics)}
                link-title="Analytics"
              >
                Analytics
              </Button>
            )}
            {!logoutOnly &&
              !isSetupRole(userRole) &&
              hasOccupancy &&
              !hasNoSpaces && (
                <Button
                  onClick={handleNavigationClick(AppRoutes.LiveData)}
                  sx={linkStyles(AppRoutes.LiveData)}
                  link-title="Live Data"
                >
                  Live Data
                </Button>
              )}
            {!logoutOnly && (
              <Button
                onClick={handleNavigationClick(AppRoutes.Spaces)}
                sx={linkStyles(AppRoutes.Spaces)}
                link-title="Spaces"
              >
                Spaces
              </Button>
            )}
            {renderUserManagementLink()}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={avatarStyles}>
              <Typography sx={{ color: "common.black" }}>
                <strong>{userInitials}</strong>
              </Typography>
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {!logoutOnly && (
                <MenuItem onClick={handleMyAccountClick}>
                  <Button startIcon={<SettingsIcon />}>My Account</Button>
                </MenuItem>
              )}
              {!logoutOnly && (
                <MenuItem
                  sx={{
                    "&:hover": {
                      "& a": { color: "primary.main" },
                    },
                  }}
                >
                  <Button
                    startIcon={<ArticleOutlinedIcon />}
                    href="https://docs.occuspace.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    component={Link}
                    sx={{ transition: "none" }}
                  >
                    Documentation
                  </Button>
                </MenuItem>
              )}
              <MenuItem onClick={handleLogoutClick}>
                <Button startIcon={<LogoutIcon />}>Logout</Button>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Appbar;
