import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import { Map } from "../../interfaces";

interface Props {
  rootStyles: BoxProps["sx"];
  isOpen: boolean;
  children: React.ReactNode;
  flexSize: number;
  ToggleIcon?: () => JSX.Element;
  collapsedSize?: number;
}

const CollapsiblePanel: React.FC<Props> = ({
  rootStyles,
  isOpen,
  children,
  ToggleIcon = () => null,
  flexSize,
  collapsedSize = 0,
}) => {
  const theme = useTheme();

  let contentBoxStyles: Map = { visibility: "hidden", opacity: 0 };
  if (isOpen) {
    contentBoxStyles = {
      transition: `all ${theme.transitions.duration.shorter}ms`,
      transitionDelay: `${theme.transitions.duration.standard}ms`,
    };
  }

  const transition = `all linear ${theme.transitions.duration.shorter}ms`;

  return (
    <Collapse
      in={isOpen}
      collapsedSize={collapsedSize}
      orientation="horizontal"
      sx={{
        ...rootStyles,
        transition,
        borderColor: "grey.200",
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        flex: isOpen ? flexSize : 0,
        "& .MuiCollapse-wrapper": {
          flexGrow: 1,
          minWidth: 0,
        },
        "& .MuiCollapse-wrapperInner": {
          flexGrow: 1,
          minWidth: 0,
        },
      }}
    >
      <ToggleIcon />
      <Box height="100%" sx={contentBoxStyles}>
        {children}
      </Box>
    </Collapse>
  );
};

export default CollapsiblePanel;
