import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import NetworkErrorAlert from "../NetworkErrorAlert";
import { errorHandler } from "../../handlers";

interface Props {
  children: React.ReactChild;
}

function ErrorFallback() {
  return <NetworkErrorAlert reload />;
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={errorHandler}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
