import React, { Dispatch } from "react";
import Box from "@mui/material/Box";
import {
  Action,
  StandardHourTabState,
  handleRuleNameChange,
} from "../../state/hours-dialog";
import HoursForm from "../HoursForm";
import TextField from "@mui/material/TextField";

interface Props {
  dispatch: Dispatch<Action>;
  standardTab: StandardHourTabState;
  isLoading: boolean;
}

const StandardHoursContent: React.FC<Props> = ({
  dispatch,
  standardTab: { ruleName, newWeekdaysWithHours },
  isLoading,
}) => {
  return (
    <Box mt={4} sx={{ opacity: isLoading ? 0.5 : 1 }}>
      <TextField
        disabled={isLoading}
        value={ruleName}
        onChange={handleRuleNameChange(dispatch)}
        placeholder="Name your hour rule"
        fullWidth
        size="small"
        autoComplete="rule-name"
        label="Hour Rule Name"
        sx={{
          mt: 4,
        }}
      />
      <Box display="flex" gap={8}>
        <Box flex={1}>
          <HoursForm
            newWeekdaysWithHours={newWeekdaysWithHours}
            dispatch={dispatch}
            sliderMaxWidth={660}
            isLoading={isLoading}
            tabName="standardTab"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default StandardHoursContent;
