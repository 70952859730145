import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  text: string;
  error: boolean;
}

const PasswordStrengthStatus = ({ text, error }: Props) => {
  if (error) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <CloseIcon color="error" fontSize="inherit" />
        <Typography variant="caption" color="error">
          {text}
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <CheckIcon color="success" fontSize="inherit" />
      <Typography variant="caption" color="success.main">
        {text}
      </Typography>
    </Box>
  );
};

export default PasswordStrengthStatus;
