import React from "react";
import AppBar from "./AppBar";
import MobileAppBar from "./MobileAppBar";
import { AppRoutes } from "../../routing";

interface Props {
  logoutOnly?: boolean;
  handleNavigationClick?: (route: AppRoutes) => void;
  isMobileSized: boolean;
}

const OccuAppBar: React.FC<Props> = ({
  isMobileSized,
  handleNavigationClick = () => null,
  logoutOnly = false,
}) => {
  const props = { logoutOnly, onNavigationClick: handleNavigationClick };
  if (isMobileSized) {
    return <MobileAppBar {...props} />;
  }

  return <AppBar {...props} />;
};

export default OccuAppBar;
