import React, { useContext } from "react";
import ViewContext from "../../ViewContext";
import CollapsiblePanel from "../../components/CollapsiblePanel";
import RightPanel from "../../components/RightPanel";

interface Props {
  spaceId: number;
  onError: () => void;
}

const CollapsibleRightPanel: React.FC<Props> = ({ spaceId, onError }) => {
  const { isRightPanelOpen } = useContext(ViewContext);

  return (
    <CollapsiblePanel
      isOpen={isRightPanelOpen}
      rootStyles={{ borderLeft: 1 }}
      flexSize={2}
    >
      <RightPanel onError={onError} spaceId={spaceId as number} />
    </CollapsiblePanel>
  );
};

export default CollapsibleRightPanel;
