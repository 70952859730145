import React, { forwardRef, useMemo } from "react";
import { SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UndoIcon from "../../icons/Undo";
import { OccuSnackbarVariant } from "../../interfaces";
import { dropDownBoxShadow } from "../../theme";

interface Props {
  title: SnackbarMessage;
  subTitle: string;
  snackBarKey: SnackbarKey;
  actionLabel?: string;
  onClick?: () => void;
  variant?: OccuSnackbarVariant;
  Icon?: React.FC;
}

const DefaultIcon = () => <CheckCircleOutlinedIcon color="success" />;

const OccuSnackbar = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      subTitle,
      snackBarKey,
      actionLabel,
      onClick,
      variant,
      Icon = DefaultIcon,
    },
    ref
  ) => {
    const { closeSnackbar } = useSnackbar();

    const [actionColor, actionIcon] = useMemo(() => {
      if (variant === OccuSnackbarVariant.Edit) {
        return ["primary.main", <EditOutlinedIcon />];
      }
      if (variant === OccuSnackbarVariant.Undo) {
        return ["grey.600", <UndoIcon />];
      }
      return ["primary.main", null];
    }, [variant]);

    const handleDismiss = () => {
      closeSnackbar(snackBarKey);
    };

    const renderActionButton = () => {
      if (!actionLabel || !actionIcon || !onClick) {
        return null;
      }
      return (
        <Button
          startIcon={actionIcon}
          sx={{
            ml: 8,
            textDecoration: "none",
            color: actionColor,
          }}
          onClick={onClick}
        >
          {actionLabel}
        </Button>
      );
    };

    return (
      <Card
        sx={{
          width: 360,
          py: 3,
          px: 4,
          borderRadius: { xs: 2 },
          boxShadow: dropDownBoxShadow,
        }}
        ref={ref}
        elevation={4}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={2} alignItems="center">
            <Icon />
            <Typography variant="body2">
              <strong>{title}</strong>
            </Typography>
          </Box>
          <IconButton onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography ml={8} mb={1} variant="body2">
          {subTitle}
        </Typography>
        {renderActionButton()}
      </Card>
    );
  }
);

export default OccuSnackbar;
