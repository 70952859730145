import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import RemoveIcon from "@mui/icons-material/Remove";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { SpaceStatus } from "../../interfaces";
import { formatNumberWithComma } from "../../util/format";

interface Props {
  status: SpaceStatus;
  liveCount?: number;
  liveTotal?: number;
  icon?: boolean;
  hasToolTip?: boolean;
}

interface WrapperProps {
  children: React.ReactNode;
  text: string;
  hasToolTip?: boolean;
}

const StatusWrapper: React.FC<WrapperProps> = ({
  children,
  text,
  hasToolTip,
}) => {
  if (hasToolTip) {
    return (
      <Tooltip title={text} placement="right">
        <Box display="flex" gap={2} alignItems="center">
          {children}
        </Box>
      </Tooltip>
    );
  }
  return <>{children}</>;
};

const SpaceStatusIndicator: React.FC<Props> = ({
  status,
  liveCount,
  liveTotal,
  icon = true,
  hasToolTip,
}) => {
  const renderStatus = () => {
    if (status === SpaceStatus.Live) {
      const livetext =
        liveCount && liveTotal
          ? `Live (${formatNumberWithComma(liveCount)}/${formatNumberWithComma(
              liveTotal
            )})`
          : "Live";
      return (
        <StatusWrapper
          text="Space is live and returning data"
          hasToolTip={Boolean(hasToolTip)}
        >
          {icon && (
            <CircleIcon key="icon" sx={{ fontSize: 8 }} color="success" />
          )}
          <Typography key="typography" color="success.main">
            {livetext}
          </Typography>
        </StatusWrapper>
      );
    }
    if (status === SpaceStatus.Inactive) {
      return (
        <StatusWrapper
          text="Space is inactive and not reporting data"
          hasToolTip={Boolean(hasToolTip)}
        >
          {icon && (
            <RemoveIcon key="icon" sx={{ color: "grey.500", fontSize: 8 }} />
          )}
          <Typography key="typography" color="grey.500">
            Inactive
          </Typography>
        </StatusWrapper>
      );
    }
    if (status === SpaceStatus.Setup) {
      return (
        <StatusWrapper
          text="Space needs to be setup and sensors installed"
          hasToolTip={Boolean(hasToolTip)}
        >
          {icon && (
            <CircleOutlinedIcon
              key="icon"
              color="warning"
              sx={{ fontSize: 8 }}
            />
          )}
          <Typography key="typography" color="warning.main">
            Setup
          </Typography>
        </StatusWrapper>
      );
    }
  };
  return (
    <Box display="flex" alignItems="center" columnGap={2}>
      {renderStatus()}
    </Box>
  );
};

export default SpaceStatusIndicator;
