import React, { Dispatch, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import DialogHeader from "components/DialogHeader";
import LoadingButton from "@mui/lab/LoadingButton";
import BreakHoursContent from "./BreakHoursContent";
import SpecialHoursContent from "./SpecialHoursContent";
import StandardHoursContent from "./StandardHoursContent";
import {
  HourRuleDialogState,
  handleTabChange,
  Action,
  DialogType,
  handleHourRuleSelection,
  handleAddHourBreakClick,
  handleCancelAddBreakClick,
  handleAddHourSpecialClick,
  handleCancelAddHourSpecialClick,
  handleCreateHourRuleClick,
  getWeekdaysInRange,
} from "../../state/hours-dialog";
import { AnyVal } from "types";
import CreateHourRuleDialog from "../CreateHourRuleDialog";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

type Props = {
  dispatch: Dispatch<Action>;
  dialogState: HourRuleDialogState;
  data: AnyVal; // TODO: interface
  onClose: () => void;
  onGetHourBreakByIdClick: (hourBreakId: number) => () => void;
  onEditStandardRuleSubmit: () => void;
  onEditHourBreakSubmit: () => void;
  onEditHourSpecialSubmit: () => void;
  onAssignHourRuleOptionClick: () => void;
  onCreateHourBreakSubmit: () => void;
  onCreateHourSpecialSubmit: () => void;
  onAssignHourRuleSubmit: (hourRuleId: number | null) => () => void;
  onCreateHourRuleSubmit: () => void;
  isHoursTab?: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`hour-tabpanel-${index}`}
      aria-labelledby={`hour-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `hour-tab-${index}`,
    "aria-controls": `hour-tabpanel-${index}`,
  };
}

const HourRuleDialog: React.FC<Props> = ({
  dispatch,
  dialogState: {
    isOpen,
    errorMessage,
    isLoading,
    currentTab,
    currentHourBreakId,
    currentHourSpecialId,
    standardTab,
    breakHoursTab,
    specialHoursTab,
    previousSpecialHoursTab,
    dialogType,
    assignHourRules,
    hasOverlap,
  },
  data,
  onClose,
  onGetHourBreakByIdClick,
  onEditStandardRuleSubmit,
  onEditHourBreakSubmit,
  onEditHourSpecialSubmit,
  onAssignHourRuleOptionClick,
  onCreateHourBreakSubmit,
  onCreateHourSpecialSubmit,
  onAssignHourRuleSubmit,
  onCreateHourRuleSubmit,
  isHoursTab,
}) => {
  const locationsUsingThisHourRule = data?.locationsUsingThisHourRule || 0;

  const deriveSubmit = () => {
    if (currentTab === 0) {
      return onEditStandardRuleSubmit;
    }
    if (currentTab === 1) {
      if (breakHoursTab.isAddMode) {
        return onCreateHourBreakSubmit;
      }
      return onEditHourBreakSubmit;
    }
    if (currentTab === 2) {
      if (specialHoursTab.isAddMode) {
        return onCreateHourSpecialSubmit;
      }
      return onEditHourSpecialSubmit;
    }
    return onEditStandardRuleSubmit;
  };
  const onSubmit = deriveSubmit();

  const weekdaysInRange = useMemo(() => {
    return getWeekdaysInRange(breakHoursTab.dateRange);
  }, [breakHoursTab.dateRange]);

  const isSubmitDisabled = useMemo(() => {
    if (isLoading) return true;
    if (currentTab === 0 || dialogType === DialogType.CreateHourRule) {
      if (hasOverlap) return true;
      let hasEmptySetHour = false;
      standardTab.newWeekdaysWithHours.forEach((weekdayWithHours) => {
        if (
          !weekdayWithHours.isAlwaysOpen &&
          !weekdayWithHours.isAlwaysClosed
        ) {
          const weekdayHasEmptySetHour = weekdayWithHours.setHours.some(
            (setHour) => setHour.open === "" || setHour.close === ""
          );
          if (weekdayHasEmptySetHour) {
            hasEmptySetHour = true;
          }
        }
      });

      if (hasEmptySetHour) {
        return true;
      }
      const stringifiedWeekdaysWithHours = JSON.stringify(
        standardTab.weekdaysWithHours
      );
      const stringifiedNewWeekdaysWithHours = JSON.stringify(
        standardTab.newWeekdaysWithHours
      );
      const isWeekdaysWithHoursEqual =
        stringifiedWeekdaysWithHours === stringifiedNewWeekdaysWithHours;
      if (isWeekdaysWithHoursEqual) {
        if (standardTab.ruleName !== data.name) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    if (currentTab === 1) {
      if (!currentHourBreakId && !breakHoursTab.isAddMode) {
        return true;
      }
      if (hasOverlap) return true;
      let hasEmptySetHour = false;
      breakHoursTab.newWeekdaysWithHours.forEach((weekdayWithHours) => {
        if (
          !weekdayWithHours.isAlwaysOpen &&
          !weekdayWithHours.isAlwaysClosed
        ) {
          const weekdayHasEmptySetHour = weekdayWithHours.setHours.some(
            (setHour) => setHour.open === "" || setHour.close === ""
          );
          if (weekdayHasEmptySetHour) {
            const isWeekdayInRange = weekdaysInRange.includes(
              weekdayWithHours.day.id
            );
            if (isWeekdayInRange) {
              hasEmptySetHour = true;
            }
          }
        }
      });
      if (hasEmptySetHour) {
        return true;
      }
      const stringifiedNewWeekdaysWithHours = JSON.stringify(
        breakHoursTab.newWeekdaysWithHours
      );
      const stringifiedWeekdaysWithHours = JSON.stringify(
        breakHoursTab.weekdaysWithHours
      );
      const isWeekdaysWithHoursEqual =
        stringifiedWeekdaysWithHours === stringifiedNewWeekdaysWithHours;
      if (isWeekdaysWithHoursEqual) {
        const isNameSame =
          breakHoursTab.breakName === breakHoursTab.previousBreakName;
        const isDateRangeSame =
          breakHoursTab.dateRange[0]?.toISO() ===
            breakHoursTab.previousDateRange[0]?.toISO() &&
          breakHoursTab.dateRange[1]?.toISO() ===
            breakHoursTab.previousDateRange[1]?.toISO();

        if (!isNameSame || !isDateRangeSame) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    }
    if (currentTab === 2) {
      if (!currentHourSpecialId && !specialHoursTab.isAddMode) {
        return true;
      }
      if (specialHoursTab.isAddMode) {
        const { isAllClose, isAllOpen, setHour } = specialHoursTab;
        if (isAllClose || isAllOpen) return false;
        if (setHour.open === "" || setHour.close === "") return true;
        return false;
      }
      if (!specialHoursTab.date) {
        return true;
      }
      if (
        specialHoursTab.isAllOpen === previousSpecialHoursTab.isAllOpen &&
        specialHoursTab.isAllClose === previousSpecialHoursTab.isAllClose &&
        specialHoursTab.setHour.open === previousSpecialHoursTab.setHour.open &&
        specialHoursTab.setHour.close ===
          previousSpecialHoursTab.setHour.close &&
        specialHoursTab.date?.toISO() ===
          previousSpecialHoursTab.date?.toISO() &&
        specialHoursTab.hourSpecialName ===
          previousSpecialHoursTab.hourSpecialName
      ) {
        return true;
      }

      // TODO: check previous state and compare
      return false;
    }
    return false;
  }, [
    standardTab,
    isLoading,
    currentTab,
    dialogType,
    hasOverlap,
    breakHoursTab,
    currentHourBreakId,
    specialHoursTab,
    currentHourSpecialId,
  ]);

  const options = useMemo(() => {
    if (!assignHourRules.data) return [];
    const { globalHourRules, customerHourRules } = assignHourRules.data;
    return [...globalHourRules, ...customerHourRules];
  }, [assignHourRules.data]);

  if (dialogType === DialogType.AssignHourrule) {
    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogHeader title={"Manage Hours"} onClose={onClose} useForestTheme />
        <DialogContent dividers>
          <Box>
            <Typography color="grey.600" pb={4}>
              Choose an existing hour rule that belongs to you from the
              drop-down below to assign to this space, or{" "}
              <Link
                underline="none"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={handleCreateHourRuleClick(dispatch)}
              >
                create a new hour rule
              </Link>
              .
            </Typography>
            <Autocomplete
              loading={assignHourRules.isLoading}
              value={assignHourRules.currentSelectedHourRule}
              onChange={handleHourRuleSelection(dispatch)}
              sx={{ pt: 6 }}
              fullWidth
              options={options}
              groupBy={(option) =>
                option.customerId === 1 ? "Global Rules" : "Custom Rules"
              }
              getOptionLabel={(option) => option.name || "-"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ "& label": { width: "100%" } }}
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        color="inherit"
                        fontSize="inherit"
                        fontWeight="inherit"
                        lineHeight="inherit"
                      >
                        Available Hour Rules
                      </Typography>
                    </Box>
                  }
                />
              )}
              renderGroup={(params) => [
                params.group === "Custom Rules" ? (
                  <Box
                    key={params.key}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    bgcolor="grey.100"
                  >
                    <Typography fontWeight={600} color="grey.500">
                      {params.group}
                    </Typography>
                    <Link
                      underline="none"
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={handleCreateHourRuleClick(dispatch)}
                    >
                      Create new
                    </Link>
                  </Box>
                ) : (
                  <Typography
                    key={params.key}
                    fontWeight={600}
                    color="grey.500"
                    sx={{ bgcolor: "grey.100", p: 2 }}
                  >
                    {params.group}
                  </Typography>
                ),
                params.children,
              ]}
            />
          </Box>
          {errorMessage && (
            <Box display="flex" justifyContent="end">
              <Typography color="error" variant="body2" justifySelf="end">
                {errorMessage}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            disabled={isLoading}
            type="submit"
            variant="contained"
            fullWidth
            onClick={onAssignHourRuleSubmit(
              assignHourRules.currentSelectedHourRule?.id || null
            )}
          >
            Assign Hour Rule
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  if (dialogType === DialogType.CreateHourRule) {
    const isGlobalRule = data?.customerId ? data?.customerId === 1 : false;
    return (
      <CreateHourRuleDialog
        isOpen={isOpen}
        dispatch={dispatch}
        standardTab={standardTab}
        isLoading={isLoading}
        errorMessage={errorMessage}
        hasOverlap={hasOverlap}
        onClose={onClose}
        onSubmit={onCreateHourRuleSubmit}
        onAssignHourRuleOptionClick={onAssignHourRuleOptionClick}
        isDisabled={isSubmitDisabled}
        isGlobalRule={isGlobalRule}
        isHoursTab={isHoursTab}
      />
    );
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      sx={{ " & .MuiPaper-root": { maxWidth: 1100 } }}
      fullWidth
    >
      <DialogHeader
        useForestTheme
        title={
          <Box display="flex" gap={2} alignItems="center">
            <Typography variant="h6">Manage Hours</Typography>
            {locationsUsingThisHourRule > 1 && (
              <Box display="flex" alignItems="center" gap={1}>
                <WarningAmberRoundedIcon sx={{ color: "error.main" }} />
                <Typography variant="body2" color="error">
                  This hour rule is being used by {locationsUsingThisHourRule}{" "}
                  locations. Editing this rule will affect all locations using
                  it.
                </Typography>
              </Box>
            )}
          </Box>
        }
        onClose={onClose}
      />
      <DialogContent dividers sx={{ pt: isHoursTab ? 4 : 0 }}>
        {isHoursTab && (
          <Typography color="grey.600" gutterBottom>
            Edit the hour rule for this space below by moving the sliders and/or
            using the editing controls. If you have special hours during
            holidays, breaks, or other occasions you can input or edit those as
            well below.
          </Typography>
        )}
        {!isHoursTab && (
          <Box my={4}>
            <Typography color="grey.600" gutterBottom>
              Edit the hour rule for this space below by moving the sliders
              and/or using the editing controls. If you have special hours
              during holidays, breaks, or other occasions you can input or edit
              these as well below.
            </Typography>
            <Typography color="grey.600">
              You can also{" "}
              <Link
                underline="none"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={onAssignHourRuleOptionClick}
              >
                assign a different existing hour rule
              </Link>{" "}
              to this space from a list of hour rules that belong to you, or{" "}
              <Link
                underline="none"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={handleCreateHourRuleClick(dispatch)}
              >
                create a new hour rule.
              </Link>
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "grey.300",
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange(dispatch)}
            aria-label="basic tabs example"
          >
            <Tab label="Standard Hours" {...a11yProps(0)} />
            <Tab label="Special Hours (Multiple Days)" {...a11yProps(1)} />
            <Tab label="Special Hours (Single Day)" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <StandardHoursContent
            dispatch={dispatch}
            standardTab={standardTab}
            isLoading={isLoading}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Box mt={4} mb={2} display="flex" justifyContent="space-between">
            <Typography color="grey.600">
              Set special hours for date ranges that span multiple days (e.g. a
              winter break).
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={
                breakHoursTab.isAddMode
                  ? handleCancelAddBreakClick(dispatch)
                  : handleAddHourBreakClick(dispatch)
              }
            >
              {breakHoursTab.isAddMode ? "Cancel" : "Add New Special Hours"}
            </Button>
          </Box>
          <BreakHoursContent
            dispatch={dispatch}
            hourBreaks={data?.hourBreaks}
            breakHoursTab={breakHoursTab}
            currentHourBreakId={currentHourBreakId}
            onGetHourBreakByIdClick={onGetHourBreakByIdClick}
            isLoading={isLoading}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Box mt={4} mb={2} display="flex" justifyContent="space-between">
            <Typography color="grey.600">
              Set special hours for a single date (e.g. a specific holiday or
              occasion).
            </Typography>
            <Button
              variant="contained"
              size="small"
              onClick={
                specialHoursTab.isAddMode
                  ? handleCancelAddHourSpecialClick(dispatch)
                  : handleAddHourSpecialClick(dispatch)
              }
            >
              {specialHoursTab.isAddMode ? "Cancel" : "Add New Special Hours"}
            </Button>
          </Box>
          <SpecialHoursContent
            dispatch={dispatch}
            hourSpecials={data?.hourSpecials}
            specialHoursTab={specialHoursTab}
            currentHourSpecialId={currentHourSpecialId}
            isLoading={isLoading}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Box>
          {errorMessage && (
            <Box display="flex" justifyContent="end" mt={2}>
              <Typography color="error" variant="body2" justifySelf="end">
                {errorMessage}
              </Typography>
            </Box>
          )}
          {hasOverlap && (
            <Box display="flex" justifyContent="end" mt={2}>
              <Typography color="error" variant="body2" justifySelf="end">
                Overlapping hours, please adjust
              </Typography>
            </Box>
          )}
        </Box>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={isSubmitDisabled}
          onClick={onSubmit}
        >
          {currentTab === 0 ? "Save Standard Hours" : "Save Special Hours"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default HourRuleDialog;
