import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";
import { SvgIconProps } from "@mui/material/SvgIcon";

const SpacesSvgIcon = createSvgIcon(
  <path
    d="m4.864 7.823 3.88 2.303 3.88-2.303m-3.88 6.896v-4.597M4.865 7.84a.93.93 0 0 1-.12-.455v-3.65a.93.93 0 0 1 .12-.455.9.9 0 0 1 .325-.333L8.3 1.122a.871.871 0 0 1 .89 0l3.11 1.825a.9.9 0 0 1 .325.333.93.93 0 0 1 .12.456v3.649a.93.93 0 0 1-.12.455m-7.76-4.579 3.88 2.304 3.88-2.304m-3.88 6.897V5.56m0 8.667v-3.65m0 3.65a.93.93 0 0 1-.12.455.901.901 0 0 1-.325.334L5.19 16.84a.871.871 0 0 1-.89 0l-3.11-1.824a.901.901 0 0 1-.326-.334.93.93 0 0 1-.12-.455v-3.65a.93.93 0 0 1 .12-.455.9.9 0 0 1 .325-.333L4.3 7.964a.872.872 0 0 1 .89 0L8.3 9.79a.9.9 0 0 1 .325.333.93.93 0 0 1 .12.456m0 3.649c0 .16.04.317.119.455.078.139.19.254.325.334L12.3 16.84a.87.87 0 0 0 .89 0l3.11-1.824a.9.9 0 0 0 .325-.334.93.93 0 0 0 .12-.455v-3.65a.93.93 0 0 0-.12-.455.9.9 0 0 0-.325-.333l-3.11-1.825a.872.872 0 0 0-.89 0L9.19 9.79a.9.9 0 0 0-.326.333.93.93 0 0 0-.12.456m-7.88-.475 3.88 2.304 3.88-2.304M4.744 17v-4.598m4.12-2.299 3.88 2.304 3.88-2.304M12.744 17v-4.598"
    fill="none"
    strokeWidth={0.833}
    strokeLinecap="round"
    strokeLinejoin="round"
    stroke="currentColor"
  />,
  "Spaces"
);

// https: mui.com/material-ui/react-tooltip/#custom-child-element
const Spaces = React.forwardRef<HTMLDivElement, SvgIconProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <SpacesSvgIcon viewBox="0 0 18 18" {...props} ref={ref as any} />;
});

export default Spaces;
