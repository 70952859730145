import React from "react";
import { Slider } from "@mui/material";

interface HoursSliderProps {
  currentColor: string;
  sliderMaxWidth?: number;
  sliderValue: number[];
  isLoading: boolean;
  onSliderChange: (event: Event, newValue: number | number[]) => void;
}

const HoursSlider: React.FC<HoursSliderProps> = ({
  currentColor,
  sliderMaxWidth,
  sliderValue,
  isLoading,
  onSliderChange,
}) => {
  return (
    <Slider
      disabled={isLoading}
      value={sliderValue}
      onChange={onSliderChange}
      min={0}
      max={96}
      marks={Array.from({ length: 23 }, (_, i) => i + 1).map((i) => ({
        value: i * 4,
      }))}
      sx={{
        p: 0,
        width: sliderMaxWidth || 230,
        cursor: "pointer",
        "& .MuiSlider-thumb": {
          bgcolor: currentColor,
          height: 18,
          width: 18,
        },
        "& .MuiSlider-rail": {
          height: 10,
        },
        "& .MuiSlider-track": {
          background: "unset",
          bgcolor: currentColor,
          border: "none",
          height: 10,
        },
        // show marks
        "& .MuiSlider-mark": {
          border: "none",
          width: "1px",
          height: "5px",
          borderRadius: 0,
        },
      }}
    />
  );
};

export default HoursSlider;
