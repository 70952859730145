import React, { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import TreeContext from "../../TreeContext";
import ViewContext from "../../ViewContext";
import CollapsiblePanel from "../../components/CollapsiblePanel";
import { leftPanelToggleIconId } from "../../theme";
import SpaceTreePanel from "components/SpaceTreePanel";
import { SpaceTreeVariant } from "common/trees";
import DesktopTreePanelIcon from "../DesktopTreePanelIcon";
import { getCurrentFirstPath, AppRoutes } from "../../routing";
import { getSpaceNodesByIdMap } from "common/trees";

interface Props {
  spaceId: number;
}

const ToggleIcon = () => <DesktopTreePanelIcon />;

const CollapsibleTreePanel: React.FC<Props> = ({ spaceId }) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const { isLeftPanelOpen, isMobileSized } = useContext(ViewContext);
  const { allSetup, lockNonLiveSpaces, handleTreeNodeSelection, rawTreeData } =
    useContext(TreeContext);
  const firstPath = getCurrentFirstPath(pathname);

  const hideFiltration =
    firstPath === AppRoutes.Analytics || firstPath === AppRoutes.LiveData;

  const { trees, nodes } = useMemo(() => {
    const parsedData = JSON.parse(rawTreeData);
    const trees = parsedData["tree"];
    const nodes = getSpaceNodesByIdMap(trees);
    return { trees, nodes };
  }, [rawTreeData]);

  const memoizedTreePanel = useMemo(() => {
    return (
      <SpaceTreePanel
        hideFiltrationSpaces={hideFiltration}
        variant={SpaceTreeVariant.Spaces}
        spaceId={spaceId}
        isMobileSized={isMobileSized}
        allSetup={allSetup}
        lockNonLiveSpaces={lockNonLiveSpaces}
        nodes={nodes}
        trees={trees}
        firstPath={firstPath}
        onTreeNodeSelection={handleTreeNodeSelection}
        useForestTheme
      />
    );
  }, [
    spaceId,
    hideFiltration,
    isMobileSized,
    allSetup,
    lockNonLiveSpaces,
    firstPath,
    nodes,
    trees,
    firstPath,
  ]);

  return (
    <CollapsiblePanel
      isOpen={isLeftPanelOpen}
      ToggleIcon={ToggleIcon}
      collapsedSize={20}
      flexSize={2}
      rootStyles={{
        borderRight: 1,
        // When hovering on the collapse, make toggle icon visible
        [`&:hover #${leftPanelToggleIconId}`]: {
          visibility: "visible",
          opacity: 1,
          transition: `visibility 0s linear 0s, opacity ${theme.transitions.duration.shorter}ms`,
        },
        // Position relative so close Icon can be absolute
        // with the collapse as it's parent
        position: "relative",
        overflow: "visible",
      }}
    >
      {memoizedTreePanel}
    </CollapsiblePanel>
  );
};

export default CollapsibleTreePanel;
