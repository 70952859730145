import React from "react";
import { Products, SpaceNode } from "./interfaces";

interface TreeContext {
  hasNoSpaces: boolean;
  allSetup: boolean;
  disableFiltrationSpaces: boolean;
  loading: boolean;
  lockNonLiveSpaces: boolean;
  handleTreeNodeSelection: (id: string) => void;
  nodes: { [key: string]: SpaceNode };
  trees: SpaceNode[];
  products: Products[];
  rawTreeData: string;
}

const TreeContext = React.createContext<TreeContext>({
  hasNoSpaces: false,
  allSetup: false,
  disableFiltrationSpaces: false,
  loading: false,
  lockNonLiveSpaces: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleTreeNodeSelection: () => {},
  nodes: {},
  trees: [],
  products: [],
  rawTreeData: "",
});

export default TreeContext;
