import React from "react";
import Box from "@mui/material/Box";
import { Image } from "../../interfaces";
import Thumbnail from "../Thumbnail";

interface Props {
  selectedIndex: number;
  images: Image[];
  onClick: (index: number) => () => void;
}

const ThumbnailList: React.FC<Props> = ({ selectedIndex, images, onClick }) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={3} p={1}>
      {images.map(({ name, url }, index) => {
        const isSelected = index === selectedIndex;
        return (
          <Thumbnail
            name={name || "thumbnail"}
            url={url}
            isSelected={isSelected}
            onClick={onClick(index)}
          />
        );
      })}
    </Box>
  );
};

export default ThumbnailList;
