export enum FetchPolicy {
  NoCache = "no-cache",
}

export enum Queries {
  GetSpace = "GetSpace",
  GetMe = "GetMe",
  GetSpaceAnalytics = "GetSpaceAnalytics",
  GetLiveData = "GetLiveData",
  GetMySpaceTrees = "GetMySpaceTrees",
  GetPortalRightPanel = "GetPortalRightPanel",
}

export interface NetworkState {
  error: boolean;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const EditSpaceNameRefetchQueries = [
  Queries.GetSpaceAnalytics,
  Queries.GetLiveData,
  Queries.GetMySpaceTrees,
];

export const EditCapacityRefetchQueries = [
  Queries.GetSpaceAnalytics,
  Queries.GetLiveData,
  Queries.GetMySpaceTrees,
];

export const InstallSensorRefetchQueries = [Queries.GetMySpaceTrees];

// an infinite scroll helper function
// when a refetch is made on data which populates an infinite scroll,
// we refetch a single page that includes as many items up to maxPageSize (generally 500)
// if we don't fetch everything (say there are 750 items in the infinite scroll, but our max is 500),
// we return a new current page that reflects all items retrieved up to the maxPageSize
export function deriveInfiniteScrollRefreshMetadata(
  normalPageSize: number,
  currentPage: number,
  maxPageSize: number
) {
  const proposedSize = normalPageSize * currentPage;
  if (proposedSize > maxPageSize) {
    const pageSize = maxPageSize - (maxPageSize % normalPageSize);
    return {
      page: Math.floor(maxPageSize / normalPageSize),
      pageSize,
    };
  }

  return {
    page: currentPage,
    pageSize: proposedSize,
  };
}

export interface ApolloError {
  message: string;
}
