import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { ZoneStatus } from "../../interfaces";

interface Props {
  status: ZoneStatus;
}

const ZoneStatusIndicator: React.FC<Props> = ({ status }) => {
  if (status === ZoneStatus.Live) {
    return (
      <Typography variant="body2" color="success.main">
        Live
      </Typography>
    );
  }
  if (status === ZoneStatus.Inactive) {
    return (
      <Typography variant="body2" color="grey.500">
        Inactive
      </Typography>
    );
  }
  if (status === ZoneStatus.Setup) {
    return (
      <Tooltip title="Needs Setup" placement="right">
        <WarningAmberRoundedIcon color="error" />
      </Tooltip>
    );
  }
  if (status === ZoneStatus.Calibration) {
    return (
      <Tooltip title="Needs Head Counts" placement="right">
        <WarningAmberRoundedIcon color="error" />
      </Tooltip>
    );
  }
  if (status === ZoneStatus.Training) {
    return (
      <Typography variant="body2" color="grey.500">
        Training
      </Typography>
    );
  }
  if (status === ZoneStatus.Inreview) {
    return (
      <Typography variant="body2" color="grey.500">
        In Review
      </Typography>
    );
  }
  return null;
};

export default ZoneStatusIndicator;
