import React from "react";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LiveDataComponent from "../../components/LiveData";
import GreyBoxLabel from "./GreyBoxLabel";

interface Props {
  lastUpdated: string;
  occupancy: number;
  percent: number;
}

const LiveData: React.FC<Props> = ({ lastUpdated, occupancy, percent }) => {
  const formattedTimeStamp = DateTime.fromISO(lastUpdated).toLocaleString(
    DateTime.TIME_SIMPLE
  );
  return (
    <>
      <GreyBoxLabel title="Live Data" />
      <Box>
        <Typography variant="label" gutterBottom>
          As of {formattedTimeStamp}
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <LiveDataComponent
            occupancy={occupancy}
            percent={percent}
            typographyStyles={{ fontWeight: "bold" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default LiveData;
