import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";

type Props = Record<string, never>;

const UndoSvgIcon = createSvgIcon(
  <svg fill="none" stroke="currentColor">
    <path
      vectorEffect="non-scaling-stroke"
      transform="matrix(1.29 0 0 1.29 1.882 1.822)"
      d="M4.8 4 1.6 7.2l3.2 3.2"
      strokeLinecap="round"
    />
    <path
      vectorEffect="non-scaling-stroke"
      transform="matrix(1.29 0 0 1.29 1.883 1.821)"
      d="M13 11v-.6a3.2 3.2 0 0 0-3.2-3.2H1.6"
      strokeLinecap="round"
    />
  </svg>,
  "Undo"
);

const Undo: React.FC<Props> = () => {
  return <UndoSvgIcon />;
};

export default Undo;
