import React from "react";
import TrackerContext from "./TrackerContext";
import { useInitializeTracker } from "./occu-portal-t";
interface Props {
  children: React.ReactNode;
}

const TrackerProvider: React.FC<Props> = ({ children }) => {
  const { tracker } = useInitializeTracker();

  const TrackerContextValue = {
    tracker,
  };

  return (
    <TrackerContext.Provider value={TrackerContextValue}>
      {children}
    </TrackerContext.Provider>
  );
};

export default TrackerProvider;
