import React, { Dispatch, useMemo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { SetHour, formatToTwelveHour, WeekdayWithHours } from "common/dates";
import {
  Action,
  getWeekdaysInRange,
  handleAddSetHourClick,
  handleNineToFiveClick,
  handleSliderSetHourChange,
  handleSetHourChange,
  handleRemoveSetHourById,
  handleCheckBoxChangeClick,
  deriveSliderColor,
} from "../../state/hours-dialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import HoursSlider from "../HoursSlider";
import { DateTime } from "luxon";

interface Props {
  dispatch: Dispatch<Action>;
  newWeekdaysWithHours: WeekdayWithHours[];
  isLoading: boolean;
  tabName: string;
  sliderMaxWidth?: number;
  dateRange?: [DateTime | null, DateTime | null];
}

const step = 300;

const iconButtonStyles = {
  bgcolor: "grey.200",
  p: 1,
};

interface OpenCloseTimeRangeProps {
  dispatch: Dispatch<Action>;
  dayId: number;
  setHour: SetHour;
  index: number;
  isLoading: boolean;
  tabName: string;
}

const OpenCloseTimeRange: React.FC<OpenCloseTimeRangeProps> = ({
  dispatch,
  dayId,
  setHour: { open, close },
  index,
  isLoading,
  tabName,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        "& div.MuiOutlinedInput-root.MuiInputBase-root": {
          py: 1,
          px: 2,
        },
      }}
    >
      <TextField
        disabled={isLoading}
        type="time"
        // force am/pm to be displayed
        inputProps={{
          step,
          inputMode: "numeric",
          pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
        }}
        name="open"
        value={open}
        onChange={handleSetHourChange(dispatch, dayId, index, tabName)}
        size="small"
      />
      <Typography variant="body2" color="grey.500" px={2}>
        TO
      </Typography>
      <TextField
        disabled={isLoading}
        type="time"
        inputProps={{
          step,
          inputMode: "numeric",
          pattern: "[0-9]{2}:[0-9]{2} [AaPp][Mm]",
        }}
        name="close"
        value={close}
        onChange={handleSetHourChange(dispatch, dayId, index, tabName)}
        size="small"
      />
    </Box>
  );
};

const HoursLabelWithSlider = ({
  newWeekdayWithHours: {
    isAlwaysClosed,
    isAlwaysOpen,
    setHours,
    day: { id },
  },
  setHour,
  index,
  showRemoveButton,
  dispatch,
  isLoading,
  tabName,
  sliderMaxWidth,
}: {
  newWeekdayWithHours: WeekdayWithHours;
  setHour: SetHour;
  index: number;
  showRemoveButton: boolean;
  dispatch: Dispatch<Action>;
  isLoading: boolean;
  tabName: string;
  sliderMaxWidth?: number;
}) => {
  const { open, close } = setHour;
  const hourText = isAlwaysClosed
    ? "Closed"
    : isAlwaysOpen
    ? "Open 24 hours"
    : open && close
    ? `${formatToTwelveHour(open)} - ${formatToTwelveHour(close)}`
    : "No hours set";

  const currentColor = useMemo(
    () => `${deriveSliderColor(isAlwaysClosed)}.main`,
    [isAlwaysOpen, isAlwaysClosed]
  );
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const handleIsEditingClick = () => {
    setIsEditing((prevState) => !prevState);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
    >
      <HoursSlider
        currentColor={currentColor}
        sliderMaxWidth={sliderMaxWidth}
        sliderValue={setHour.sliderValue as number[]}
        isLoading={isLoading}
        onSliderChange={handleSliderSetHourChange(dispatch, id, index, tabName)}
      />
      <Box display="flex" alignItems="center" fontSize={16} gap={2}>
        {isEditing ? (
          <OpenCloseTimeRange
            dispatch={dispatch}
            dayId={id}
            setHour={setHour}
            index={index}
            isLoading={isLoading}
            tabName={tabName}
          />
        ) : (
          <Typography>{hourText}</Typography>
        )}
        <IconButton
          disabled={isLoading}
          sx={iconButtonStyles}
          onClick={handleIsEditingClick}
        >
          {isEditing ? (
            <CheckIcon sx={{ fontSize: 18 }} />
          ) : (
            <EditOutlinedIcon sx={{ fontSize: 18 }} />
          )}
        </IconButton>
        <IconButton
          disabled={
            isLoading ||
            isAlwaysOpen ||
            isAlwaysClosed ||
            setHours[0].open === ""
          }
          sx={iconButtonStyles}
          onClick={handleAddSetHourClick(dispatch, id, tabName)}
        >
          <AddIcon sx={{ fontSize: 18 }} />
        </IconButton>
        {showRemoveButton && (
          <IconButton
            disabled={isLoading || index === 0}
            sx={iconButtonStyles}
            onClick={handleRemoveSetHourById(dispatch, id, index, tabName)}
          >
            <CloseIcon sx={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const HoursForm: React.FC<Props> = ({
  newWeekdaysWithHours,
  dispatch,
  tabName,
  isLoading,
  sliderMaxWidth,
  dateRange,
}) => {
  const weekdaysInRange = useMemo(() => {
    if (!dateRange) {
      return getWeekdaysInRange([null, null]);
    }
    return getWeekdaysInRange(dateRange);
  }, [dateRange]);
  return (
    <Box>
      {newWeekdaysWithHours.map((newWeekdayWithHours) => {
        const isWeekdayInRange = weekdaysInRange.includes(
          newWeekdayWithHours.day.id
        );
        if (isWeekdayInRange) {
          const {
            day: { id, name },
            isNineToFive,
            isAlwaysClosed,
            isAlwaysOpen,
            setHours,
          } = newWeekdayWithHours;
          return (
            <>
              <Box key={`hours-slider-item-${id}`} py={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography fontWeight={600}>{name}</Typography>
                  <Box display="flex" gap={4}>
                    <FormControlLabel
                      disabled={isLoading}
                      sx={{ mr: 0 }}
                      control={
                        <Checkbox
                          sx={{ p: 1 }}
                          size="small"
                          checked={isNineToFive}
                          onChange={handleNineToFiveClick(
                            dispatch,
                            id,
                            tabName
                          )}
                          name="isNineToFive"
                        />
                      }
                      label={<Typography variant="label">9-5</Typography>}
                    />
                    <FormControlLabel
                      disabled={isLoading}
                      sx={{ mr: 0 }}
                      control={
                        <Checkbox
                          sx={{ p: 1 }}
                          size="small"
                          checked={isAlwaysOpen}
                          onChange={handleCheckBoxChangeClick(
                            dispatch,
                            id,
                            tabName
                          )}
                          name="isAlwaysOpen"
                        />
                      }
                      label={<Typography variant="label">24/7</Typography>}
                    />
                    <FormControlLabel
                      disabled={isLoading}
                      sx={{ mr: 0 }}
                      control={
                        <Checkbox
                          sx={{ p: 1 }}
                          size="small"
                          checked={isAlwaysClosed}
                          onChange={handleCheckBoxChangeClick(
                            dispatch,
                            id,
                            tabName
                          )}
                          name="isAlwaysClosed"
                        />
                      }
                      label={<Typography variant="label">Closed</Typography>}
                    />
                  </Box>
                </Box>
                {setHours.map((setHour, index) => {
                  const showRemoveButton = setHours.length > 1;
                  return (
                    <HoursLabelWithSlider
                      key={`hours-label-slider-${id}-${index}`}
                      setHour={setHour}
                      newWeekdayWithHours={newWeekdayWithHours}
                      index={index}
                      showRemoveButton={showRemoveButton}
                      dispatch={dispatch}
                      tabName={tabName}
                      isLoading={isLoading}
                      sliderMaxWidth={sliderMaxWidth}
                    />
                  );
                })}
              </Box>
              <Divider />
            </>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default HoursForm;
