import { formatNumberWithComma } from "../util/format";
export enum UserRoleSubtitle {
  SuperAdmin = "Full system permissions on all locations",
  Admin = "Can manage users",
  User = "Can access data",
  Setup = "Can only perform setup actions",
}

export enum TrackScructCategory {
  ExportData = "export_data",
}

export enum TrackStructAction {
  OpenModal = "click",
  SubmitModal = "submit",
}

export enum AuthMessage {
  WrongCreds = "Username and/or password is incorrect",
  DidNotGetUser = "Could not get your info. Try again or contact us.",
  DidNotGetUserAfterPasswordSet = "Could not get your info. Try again or contact us.",
  SendResetPasswordFailed = "Failed to send reset password email. Please try again or contact us.",
  Unexpected = "There was an unexpected error, please try again or contact us",
  FailedToSignOut = "Failed to signout",
  InvalidEmail = "Invalid email",
  SubmitNewPasswordFailed = "Failed to submit new password. Please try again or contact us.",
  PasswordsDoNotMatch = "Your two password values do not match",
  FirstTimeFormFail = "Failed to submit your information or retrieve an update. Please try again or contact us.",
  AfterForgotPasswordSubmit = "If the email you supplied is a valid account you will receive an email with instructions on how to reset your password",
}

export enum OccuEmails {
  Support = "support@occuspace.io",
  Account = "occuaccounts@occuspace.io",
}

export const getHeadcountText = (count: number) => {
  if (count === 1) return "1 zone needs to be counted";
  return `${formatNumberWithComma(count)} zones need to be counted`;
};

export const getInstallText = (count: number) => {
  if (count === 1) return "1 placement location needs a sensor installed";
  return `${formatNumberWithComma(
    count
  )} placement locations need sensors installed`;
};

export const getLocationCountText = (count: number) => {
  if (count === 0) return "No location permissions";
  if (count === 1) return "1 location permission";
  return `${formatNumberWithComma(count)} location permissions`;
};

export const resetPasswordError =
  "Failed to send reset password email. Please try again or contact us.";

// HoursDialog
export const getHourRuleByIdError = "Failed to get hour rule";
export const getHourBreakByIdError = "Failed to get special hours";
export const assignHourRuleError = "Failed to assign hour rule";
export const editHourStandardError = "Failed to edit standard hours";
export const createHourRuleError = "Failed to create hour rule";
export const editHourSpecialError = "Failed to edit special hours";
export const createHourSpecialError = "Failed to create special hours";
export const editHourBreakError = "Failed to edit special hours";
export const overlappingDateRangeError =
  "Date range overlaps with existing special hours (multiple days)";
export const overlappingTimeRangeError = "Overlapping hours, please adjust";
export const createHourBreakError = "Failed to create special hours";
// Toasts
export const editHourStandardSuccessTitle = "Standard hours updated";
export const editHourStandardSuccessSubTitle =
  "Standard hours successfully updated";
export const createHourRuleSuccessTitle = "Hour rule created";
export const createHourRuleSuccessSubTitle = "Hour rule successfully created";
export const editHourSpecialSuccessTitle = "Special hours updated";
export const editHourSpecialSuccessSubTitle =
  "Special hours successfully updated";
export const createHourSpecialSuccessTitle = "Special hours created";
export const createHourSpecialSuccessSubTitle =
  "Special hours successfully created";
export const editHourBreakSuccessTitle = "Special hours updated";
export const editHourBreakSuccessSubTitle =
  "Special hours successfully updated";
export const createHourBreakSuccessTitle = "Special hours created";
export const createHourBreakSuccessSubTitle =
  "Special hours successfully created";
export const assignHourRuleSuccessTitle = "Hour rule assigned";
export const assignHourRuleSuccessSubTitle = "Hour rule successfully assigned";
