import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props {
  name: string;
}

const SpaceName: React.FC<Props> = ({ name }) => {
  return (
    <Box>
      <Typography variant="label" gutterBottom>
        Name
      </Typography>
      <Typography noWrap>{name}</Typography>
    </Box>
  );
};

export default SpaceName;
