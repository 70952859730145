import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MobileNavLogo from "../../icons/MobileNavLogo";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { AppRoutes, getCurrentFirstPath } from "../../routing";
import { darkBlack } from "../../theme";
import ViewContext from "../../ViewContext";
import AuthContext from "../../authContext";
import TreeContext from "../../TreeContext";
import { SpaceTreeVariant } from "common/trees";
import SpaceTreePanel from "components/SpaceTreePanel";
import { Products } from "../../interfaces";

interface Props {
  onNavigationClick: (route: AppRoutes) => void;
  logoutOnly: boolean;
}

const HEADER_OFFSET = 14;
const navButtonStyles = { color: "primary.main" };

const MobileAppBar: React.FC<Props> = ({ logoutOnly, onNavigationClick }) => {
  const {
    allSetup,
    lockNonLiveSpaces,
    nodes,
    trees,
    handleTreeNodeSelection,
    products,
  } = useContext(TreeContext);
  const hasOccupancy = products.includes(Products.Occupancy);
  const { pathname } = useLocation();
  const firstPath = getCurrentFirstPath(pathname);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const { handleLeftPanelToggle, isLeftPanelOpen, spaceId, isMobileSized } =
    useContext(ViewContext);

  const handleMobileMenuToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleNavigationClick = (route: AppRoutes) => () => {
    onNavigationClick(route);
    setIsDrawerOpen(false);
  };

  const handleLogoutClick = async () => {
    try {
      await authContext.signout();
    } catch (e) {
      throw "failed to signout";
    }
  };

  const Icon = () => {
    return isDrawerOpen ? <CloseIcon /> : <MenuIcon />;
  };

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: (theme) => theme.zIndex.drawer + 2,
            backgroundColor: darkBlack,
          }}
        >
          <Box p={1} display="flex" alignItems="center">
            <MobileNavLogo />
          </Box>
          <IconButton
            size="large"
            color="inherit"
            aria-label="Navigation Menu"
            sx={{
              color: "common.white",
              "&:hover": { bgcolor: "transparent" },
            }}
            onClick={handleMobileMenuToggle}
          >
            <Icon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={handleMobileMenuToggle}
        hideBackdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            mt: HEADER_OFFSET,
            backgroundColor: darkBlack,
            color: "common.white",
            px: 6,
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <List>
          {!logoutOnly && (
            <ListItem disablePadding divider={false}>
              <ListItemButton
                disableGutters
                onClick={handleNavigationClick(AppRoutes.Analytics)}
              >
                <ListItemText
                  primary={<Typography variant="h3">Analytics</Typography>}
                  sx={
                    pathname.includes(AppRoutes.Analytics)
                      ? navButtonStyles
                      : {}
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          {!logoutOnly && hasOccupancy && (
            <ListItem disablePadding divider={false}>
              <ListItemButton
                disableGutters
                onClick={handleNavigationClick(AppRoutes.LiveData)}
              >
                <ListItemText
                  primary={<Typography variant="h3">Live Data</Typography>}
                  sx={
                    pathname.includes(AppRoutes.LiveData) ? navButtonStyles : {}
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          {!logoutOnly && (
            <ListItem disablePadding divider={false}>
              <ListItemButton
                disableGutters
                onClick={handleNavigationClick(AppRoutes.Spaces)}
              >
                <ListItemText
                  primary={<Typography variant="h3">Spaces</Typography>}
                  sx={
                    pathname.includes(AppRoutes.Spaces) ? navButtonStyles : {}
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          {!logoutOnly && (
            <ListItem disablePadding divider={false}>
              <ListItemButton
                disableGutters
                component={Link}
                href="https://docs.occuspace.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemText
                  primary={<Typography variant="h3">Documentation</Typography>}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding divider={false}>
            <ListItemButton disableGutters onClick={handleLogoutClick}>
              <ListItemText
                primary={<Typography variant="h3">Logout</Typography>}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Drawer
        anchor="left"
        open={isLeftPanelOpen}
        onClose={handleLeftPanelToggle}
        hideBackdrop
        PaperProps={{ sx: { width: "100%", height: "100%", mt: 14 } }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SpaceTreePanel
          firstPath={firstPath}
          variant={SpaceTreeVariant.Spaces}
          allSetup={allSetup}
          lockNonLiveSpaces={lockNonLiveSpaces}
          nodes={nodes}
          trees={trees}
          spaceId={spaceId}
          useForestTheme
          isMobileSized={isMobileSized}
          handleLeftPanelToggle={handleLeftPanelToggle}
          isLeftPanelOpen={isLeftPanelOpen}
          onTreeNodeSelection={handleTreeNodeSelection}
        />
      </Drawer>
    </>
  );
};

export default MobileAppBar;
