import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";
import { SvgIconProps } from "@mui/material/SvgIcon";

const FiltrationSpaceSvgIcon = createSvgIcon(
  <path
    d="M19.5 8.78v6.375c0 .28-.072.554-.209.796a1.574 1.574 0 0 1-.569.582l-5.444 3.188a1.527 1.527 0 0 1-1.556 0l-5.444-3.188a1.575 1.575 0 0 1-.57-.582 1.624 1.624 0 0 1-.208-.796V8.78c0-.28.072-.554.209-.796.136-.242.333-.443.569-.583l5.444-3.187a1.527 1.527 0 0 1 1.556 0L18.722 7.4c.236.14.433.34.57.583.136.242.208.516.208.796Z"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeDasharray="3 3"
  />,
  "FiltrationSpace"
);

// https: mui.com/material-ui/react-tooltip/#custom-child-element
const FiltrationSpace = React.forwardRef<HTMLDivElement, SvgIconProps>(
  (props, ref) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <FiltrationSpaceSvgIcon viewBox="0 0 24 24" {...props} ref={ref as any} />
    );
  }
);

export default FiltrationSpace;
