import { Dispatch } from "react";
import { DateTime } from "luxon";
import { AnyVal } from "types";
import {
  DaysOfTheWeek,
  SetHour,
  Day,
  WeekdayWithHours,
  convertBackendSetHourToFrontEnd,
  checkForOverlap,
  convertSliderValueToSetHour,
} from "common/dates";

export const days: Day[] = [
  { name: DaysOfTheWeek.Monday, id: 0 },
  { name: DaysOfTheWeek.Tuesday, id: 1 },
  { name: DaysOfTheWeek.Wednesday, id: 2 },
  { name: DaysOfTheWeek.Thursday, id: 3 },
  { name: DaysOfTheWeek.Friday, id: 4 },
  { name: DaysOfTheWeek.Saturday, id: 5 },
  { name: DaysOfTheWeek.Sunday, id: 6 },
];

export const deriveSliderColor = (isAlwaysClosed: boolean) => {
  if (isAlwaysClosed) return "error";
  return "success";
};

// TODO: when updating the date range, clear the set hours for days not in range
export const getWeekdaysInRange = (
  dateRange: [DateTime | null, DateTime | null]
): number[] => {
  const [startDate, endDate] = dateRange;
  const weekdays: number[] = [];

  if (!startDate || !endDate) {
    return [0, 1, 2, 3, 4, 5, 6];
  }

  let currentDate = startDate.startOf("day");
  const lastDate = endDate.startOf("day");

  let iteration = 0;
  while (currentDate <= lastDate && iteration < 7) {
    const currentDayInt = currentDate.weekday - 1;
    weekdays.push(currentDayInt);
    currentDate = currentDate.plus({ days: 1 });
    iteration++;
  }

  return weekdays;
};

export const convertSpecialHourQueryResult = (
  specialHourQueryResult: GetHourSpecialByIdQueryResult
): Partial<HourSpecialTabState> => {
  const { open, close, day, disabled, name, id } = specialHourQueryResult;
  const isAllOpen = open === "00:00:00" && close === "24:00:00";
  const isAllClose = open === "" && close === "";
  const isNineToFive = open === "09:00:00" && close === "17:00:00";
  const newSetHour = isAllClose
    ? { open: "", close: "", sliderValue: [0, 96] }
    : convertBackendSetHourToFrontEnd({ open, close });
  return {
    date: DateTime.fromISO(day, {
      setZone: true,
    }),
    hourSpecialName: name,
    setHour: newSetHour,
    isAllClose,
    isAllOpen,
    isNineToFive,
    disabled,
    id,
  };
};

export const convertDataToWeekdayWithHours = (
  standardQueryResult: StandardHourRuleQueryResult[]
): WeekdayWithHours[] => {
  const newStandard: WeekdayWithHours[] = days.map((day: Day) => {
    // find the day in the standardQueryResult
    const result = standardQueryResult.find((r) => r.weekday === day.id);
    if (result) {
      // check if hours length is 1 and open is 00:00:00 and close is 24:00:00
      const isAlwaysOpen =
        result.hours.length === 1 &&
        result.hours[0].open === "00:00:00" &&
        result.hours[0].close === "24:00:00";
      const isAlwaysClosed =
        result.hours.length === 0 ||
        (result.hours.length === 1 &&
          result.hours[0].open === "" &&
          result.hours[0].close === "");
      const isNineToFive =
        result.hours.length === 1 &&
        result.hours[0].open === "09:00:00" &&
        result.hours[0].close === "17:00:00";

      const deriveNewHours = () => {
        if (isAlwaysClosed || isAlwaysOpen)
          return [{ open: "", close: "", sliderValue: [0, 96] }];
        if (result.hours.length > 0 && result.hours[0].open === "") {
          return [{ open: "", close: "", sliderValue: [0, 96] }];
        }
        if (isNineToFive) {
          return [{ open: "09:00", close: "17:00", sliderValue: [36, 68] }];
        }
        return result.hours.map((hour) => {
          return convertBackendSetHourToFrontEnd(hour);
        });
      };

      return {
        day,
        setHours: deriveNewHours(),
        isAlwaysClosed,
        isAlwaysOpen,
        isNineToFive,
      };
    }
    return {
      day,
      setHours: [],
      isAlwaysClosed: false,
      isAlwaysOpen: false,
      isNineToFive: false,
    };
  });
  return newStandard;
};

interface StandardHourRuleQueryResult {
  weekday: number;
  hours: SetHour[];
}

export interface HourBreakQueryResult {
  disabled: boolean;
  endDate: string;
  startDate: string;
  hourRulesId: number;
  id: number;
  name: string;
}

export interface HourSpecialQueryResult {
  close: string;
  day: string;
  disabled: boolean;
  hourRulesId: number;
  id: number;
  name: string;
  open: string;
}

// TODO: remove anything that is unused
export interface GetHourRuleByIdQueryResult {
  locationsUsingThisHourRule: number;
  id: number;
  customerId: number;
  name: string;
  source: string;
  hourStandards: StandardHourRuleQueryResult[];
  hourBreaks: HourBreakQueryResult[];
  hourSpecials: HourSpecialQueryResult[];
}

export interface GetHourSpecialByIdQueryResult {
  id: number;
  name: string;
  hourRulesId: number;
  open: string;
  close: string;
  day: string;
  disabled: boolean;
}

export interface DialogState {
  isOpen: boolean;
  errorMessage: string;
  isLoading: boolean;
}

export interface StandardHoursTabState extends DialogState {
  standardWeekdayWithHours: WeekdayWithHours[];
  newStandardWeekdayWithHours: WeekdayWithHours[];
  hourRuleName: string;
}

export interface HourSpecialTabState {
  date: DateTime | null;
  hourSpecialName: string;
  setHour: SetHour;
  isAllClose: boolean;
  isAllOpen: boolean;
  isNineToFive: boolean;
  disabled: boolean;
  id: number | null;
  isAddMode: boolean;
}

export interface StandardHourTabState {
  hourRuleId: number | null;
  ruleName: string;
  weekdaysWithHours: WeekdayWithHours[];
  newWeekdaysWithHours: WeekdayWithHours[];
}

export interface BreakHoursTabState {
  weekdaysWithHours: WeekdayWithHours[];
  newWeekdaysWithHours: WeekdayWithHours[];
  dateRange: [DateTime | null, DateTime | null];
  previousDateRange: [DateTime | null, DateTime | null];
  breakName: string;
  previousBreakName: string;
  disabled: boolean;
  id: number | null;
  isAddMode: boolean;
}

export interface PageState {
  isLoading: boolean;
  errorMessage: string;
  data: null | GetHourRuleByIdQueryResult;
}

export enum DialogType {
  InitialSelect,
  EditHourRules,
  AssignHourrule,
  CreateHourRule,
}

export interface HourRule {
  id: number;
  name: string;
  source: string;
  customerId: number;
}

export interface AssignHourRulesState {
  isLoading: boolean;
  errorMessage: string;
  data: null | { customerHourRules: HourRule[]; globalHourRules: HourRule[] };
  currentSelectedHourRule: HourRule | null;
}

export interface HourRuleDialogState extends DialogState {
  currentTab: number;
  dialogType: DialogType;
  currentHourRuleId: number | null;
  currentHourBreakId: number | null;
  currentHourSpecialId: number | null;
  hasOverlap: boolean;
  currentStandardWeekdayWithHours: WeekdayWithHours[];
  standardTab: StandardHourTabState;
  breakHoursTab: BreakHoursTabState;
  specialHoursTab: HourSpecialTabState;
  previousSpecialHoursTab: HourSpecialTabState;
  assignHourRules: AssignHourRulesState;
}

export interface State {
  pageState: PageState;
  rightPanelStandard: WeekdayWithHours[];
  hourRuleDialog: HourRuleDialogState;
}

export const INITIAL_STANDARD: WeekdayWithHours[] = days.map((day: Day) => ({
  day,
  setHours: [{ open: "", close: "", sliderValue: [0, 0] }],
  isAlwaysClosed: false,
  isAlwaysOpen: false,
  isNineToFive: false,
}));

const INITIAL_SPECIAL_HOURS_TAB_STATE: HourSpecialTabState = {
  date: null,
  hourSpecialName: "",
  setHour: { open: "", close: "", sliderValue: [0, 0] },
  isAllClose: false,
  isAllOpen: false,
  isNineToFive: false,
  disabled: false,
  id: null,
  isAddMode: false,
};

// TODO: clear hours when changing route

const INITIAL_HOUR_RULE_DIALOG_STATE: HourRuleDialogState = {
  dialogType: DialogType.EditHourRules,
  isOpen: false,
  errorMessage: "",
  isLoading: false,
  currentTab: 0,
  currentHourRuleId: null,
  currentHourBreakId: null,
  currentHourSpecialId: null,
  hasOverlap: false,
  currentStandardWeekdayWithHours: INITIAL_STANDARD,
  standardTab: {
    hourRuleId: null,
    ruleName: "",
    weekdaysWithHours: INITIAL_STANDARD,
    newWeekdaysWithHours: INITIAL_STANDARD,
  },
  breakHoursTab: {
    weekdaysWithHours: INITIAL_STANDARD,
    newWeekdaysWithHours: INITIAL_STANDARD,
    dateRange: [null, null],
    previousDateRange: [null, null],
    breakName: "",
    previousBreakName: "",
    disabled: false,
    id: null,
    isAddMode: false,
  },
  specialHoursTab: INITIAL_SPECIAL_HOURS_TAB_STATE,
  previousSpecialHoursTab: INITIAL_SPECIAL_HOURS_TAB_STATE,
  assignHourRules: {
    isLoading: false,
    errorMessage: "",
    data: null,
    currentSelectedHourRule: null,
  },
};

export const INITIAL_STATE: State = {
  pageState: {
    isLoading: true,
    errorMessage: "",
    data: null,
  },
  rightPanelStandard: [],
  hourRuleDialog: INITIAL_HOUR_RULE_DIALOG_STATE,
};

export interface Action {
  type: ActionType;
  value?: AnyVal;
}

export enum ActionType {
  TabChange,
  SpaceIdChange,
  GetRuleByIdLoad,
  GetRuleByIdSuccess,
  GetRuleByIdError,
  PageLoad,
  PageSuccess,
  PageError,
  HourRuleDialogOpen,
  HourRuleDialogClose,
  // Standard Tab
  RuleNameChange,
  HourRuleDialogLoad,
  HourRuleDialogSuccess,
  HourRuleDialogError,
  // Break Tab
  GetBreakLoad,
  GetBreakSuccess,
  GetBreakError,
  BreakNameChange,
  BreakDateRangeChange,
  AddHourBreakClick,
  CancelAddHourBreakClick,
  // Special Tab
  HourSpecialNameChange,
  HourSpecialDateChange,
  HourSpecialCheckChange,
  HourSpecialNineToFiveClick,
  HourSpecialHourChange,
  HourSpecialSliderChange,
  HourSpecialIdSelect,
  AddHourSpecialClick,
  CancelAddHourSpecialClick,
  // TODO: remove form stuff below
  // New Hour Form
  SliderSetHourChange,
  SetHourChange,
  AddSetHour,
  RemoveSetHourById,
  CheckBoxChange,
  NineToFiveClick,
  // Assign Hour Rules
  GetCustomerHourRulesLoad,
  GetCustomerHourRulesSuccess,
  GetCustomerHourRulesError,
  HourRuleSelection,
  CreateHourRuleClick,
  // Create Hour Rule
}

export const dispatcher =
  (dispatch: Dispatch<Action>, action: ActionType) => (value: AnyVal) => {
    dispatch({ type: action, value });
  };

export const handleTabChange =
  (dispatch: Dispatch<Action>) =>
  (e: React.SyntheticEvent, newValue: string) => {
    dispatch({ type: ActionType.TabChange, value: newValue });
  };

export const handleHourRuleDialogOpen = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.HourRuleDialogOpen });
};

export const handleHourRuleDialogClose = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.HourRuleDialogClose });
};

export const handleRuleNameChange =
  (dispatch: Dispatch<Action>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.RuleNameChange, value: e.target.value });
  };

export const handleBreakHourNameChange =
  (dispatch: Dispatch<Action>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.BreakNameChange, value: e.target.value });
  };

export const handleBreakHourDateRangeChange =
  (dispatch: Dispatch<Action>) =>
  (date: [DateTime | null, DateTime | null]) => {
    dispatch({ type: ActionType.BreakDateRangeChange, value: date });
  };

export const handleAddHourBreakClick = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.AddHourBreakClick });
};

export const handleCancelAddBreakClick = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.CancelAddHourBreakClick });
};

export const handleAddHourSpecialClick = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.AddHourSpecialClick });
};

export const handleCancelAddHourSpecialClick =
  (dispatch: Dispatch<Action>) => () => {
    dispatch({ type: ActionType.CancelAddHourSpecialClick });
  };

export const handleSliderSetHourChange =
  (dispatch: Dispatch<Action>, dayId: number, index: number, tabName: string) =>
  (event: Event, newValue: number | number[]) => {
    dispatch({
      type: ActionType.SliderSetHourChange,
      value: { newValue, dayId, index, tabName },
    });
  };

export const handleSetHourChange =
  (dispatch: Dispatch<Action>, dayId: number, index: number, tabName: string) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.SetHourChange,
      value: { index, e, dayId, tabName },
    });
  };

export const handleAddSetHourClick =
  (dispatch: Dispatch<Action>, dayId: number, tabName: string) => () => {
    dispatch({ type: ActionType.AddSetHour, value: { dayId, tabName } });
  };

export const handleRemoveSetHourById =
  (dispatch: Dispatch<Action>, dayId: number, index: number, tabName: string) =>
  () => {
    dispatch({
      type: ActionType.RemoveSetHourById,
      value: { dayId, index, tabName },
    });
  };

export const handleCheckBoxChangeClick =
  (dispatch: Dispatch<Action>, dayId: number, tabName: string) =>
  (e: AnyVal) => {
    const { name, checked } = e.target;
    dispatch({
      type: ActionType.CheckBoxChange,
      value: { name, checked, dayId, tabName },
    });
  };

export const handleNineToFiveClick =
  (dispatch: Dispatch<Action>, dayId: number, tabName: string) =>
  (e: AnyVal) => {
    const { name, checked } = e.target;
    dispatch({
      type: ActionType.NineToFiveClick,
      value: { dayId, tabName, name, checked },
    });
  };

export const handleHourSpecialNameChange =
  (dispatch: Dispatch<Action>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.HourSpecialNameChange,
      value: e.target.value,
    });
  };

export const handleHourSpecialDateChange =
  (dispatch: Dispatch<Action>) => (date: DateTime | null) => {
    dispatch({ type: ActionType.HourSpecialDateChange, value: date });
  };

export const handleHourSpecialCheckChange =
  (dispatch: Dispatch<Action>) => (e: AnyVal) => {
    const { name, checked } = e.target;
    dispatch({
      type: ActionType.HourSpecialCheckChange,
      value: { name, checked },
    });
  };

export const handleHourSpecialNineToFiveClick =
  (dispatch: Dispatch<Action>) => (e: AnyVal) => {
    const { name, checked } = e.target;
    dispatch({
      type: ActionType.HourSpecialNineToFiveClick,
      value: { name, checked },
    });
  };

export const handleHourSpecialHourChange =
  (dispatch: Dispatch<Action>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: ActionType.HourSpecialHourChange, value: { e } });
  };

export const handleHourSpecialSliderChange =
  (dispatch: Dispatch<Action>) =>
  (event: Event, newValue: number | number[]) => {
    dispatch({
      type: ActionType.HourSpecialSliderChange,
      value: { newValue },
    });
  };

export const handleHourSpecialIdSelect =
  (dispatch: Dispatch<Action>, value: HourSpecialQueryResult) => () => {
    dispatch({ type: ActionType.HourSpecialIdSelect, value });
  };

export const handleHourRuleSelection =
  (dispatch: Dispatch<Action>) => (event: AnyVal, value: HourRule | null) => {
    dispatch({ type: ActionType.HourRuleSelection, value });
  };

export const handleCreateHourRuleClick = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: ActionType.CreateHourRuleClick });
};

export function reducer(state: State, { type, value }: Action): State {
  switch (type) {
    case ActionType.TabChange: {
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;

      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap: false,
          currentTab: value as number,
          errorMessage: "",
          standardTab: {
            ...standardTab,
            newWeekdaysWithHours: standardTab.weekdaysWithHours,
          },
          breakHoursTab: {
            ...breakHoursTab,
            newWeekdaysWithHours: breakHoursTab.weekdaysWithHours,
            dateRange: breakHoursTab.previousDateRange,
            breakName: breakHoursTab.previousBreakName,
            isAddMode: false,
          },
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            isAddMode: false,
          },
        },
      };
    }
    case ActionType.SpaceIdChange: {
      return INITIAL_STATE;
    }
    case ActionType.GetRuleByIdLoad: {
      const { hourRuleId } = value;
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: true,
          errorMessage: "",
        },
        hourRuleDialog: {
          ...INITIAL_HOUR_RULE_DIALOG_STATE,
          isLoading: true,
          isOpen: true,
          currentHourRuleId: hourRuleId,
        },
      };
    }
    case ActionType.GetRuleByIdSuccess: {
      const { data } = value;
      const newStandards = convertDataToWeekdayWithHours(data.hourStandards);
      // sort hour breaks by id. This is to ensure that the first break is the most recent
      const sortedHourBreaks = data.hourBreaks.sort(
        (a: AnyVal, b: AnyVal) => b.id - a.id
      );
      const sortedHourSpecials = data.hourSpecials.sort(
        (a: AnyVal, b: AnyVal) => b.id - a.id
      );

      const currentHourBreakId =
        sortedHourBreaks.length > 0 ? sortedHourBreaks[0].id : null;
      const currentHourSpecialId =
        sortedHourSpecials.length > 0 ? sortedHourSpecials[0].id : null;
      const formattedHoursSpecial = currentHourSpecialId
        ? convertSpecialHourQueryResult(sortedHourSpecials[0])
        : {};
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: false,
          data: {
            ...data,
            hourBreaks: sortedHourBreaks,
            hourSpecials: sortedHourSpecials,
          },
        },
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isOpen: value?.isOpen ? value.isOpen : false,
          isLoading: false,
          currentHourBreakId,
          currentHourSpecialId,
          currentStandardWeekdayWithHours: newStandards,
          standardTab: {
            hourRuleId: data.id,
            ruleName: data.name,
            weekdaysWithHours: newStandards,
            newWeekdaysWithHours: newStandards,
          },
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
          previousSpecialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
        },
      };
    }
    case ActionType.GetRuleByIdError: {
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: false,
          errorMessage: value as string,
        },
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: false,
          errorMessage: value as string,
        },
      };
    }
    case ActionType.PageLoad: {
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: true,
          errorMessage: "",
        },
      };
    }
    case ActionType.PageSuccess: {
      const { data } = value;
      const newStandards = convertDataToWeekdayWithHours(data.hourStandards);
      const sortedHourBreaks = data.hourBreaks.sort(
        (a: AnyVal, b: AnyVal) => b.id - a.id
      );
      const sortedHourSpecials = data.hourSpecials.sort(
        (a: AnyVal, b: AnyVal) => b.id - a.id
      );
      const currentHourBreakId =
        sortedHourBreaks.length > 0 ? sortedHourBreaks[0].id : null;
      const currentHourSpecialId =
        sortedHourSpecials.length > 0 ? sortedHourSpecials[0].id : null;

      const formattedHoursSpecial = currentHourSpecialId
        ? convertSpecialHourQueryResult(sortedHourSpecials[0])
        : {};
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: false,
          data: {
            ...data,
            hourBreaks: sortedHourBreaks,
            hourSpecials: sortedHourSpecials,
          },
        },
        rightPanelStandard: newStandards,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: false,
          currentHourBreakId,
          currentHourSpecialId,
          currentStandardWeekdayWithHours: newStandards,
          standardTab: {
            hourRuleId: data.id,
            ruleName: data.name,
            weekdaysWithHours: newStandards,
            newWeekdaysWithHours: newStandards,
          },
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
          previousSpecialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
        },
      };
    }
    case ActionType.PageError: {
      return {
        ...state,
        pageState: {
          ...state.pageState,
          isLoading: false,
          errorMessage: value as string,
        },
      };
    }
    case ActionType.RuleNameChange: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            ruleName: value as string,
          },
        },
      };
    }
    case ActionType.HourRuleDialogLoad: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: true,
          errorMessage: "",
        },
      };
    }
    case ActionType.HourRuleDialogSuccess: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isOpen: value?.isOpen ? value.isOpen : false,
          isLoading: false,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            isAddMode: false,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            isAddMode: false,
          },
        },
      };
    }
    case ActionType.HourRuleDialogError: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: false,
          errorMessage: value as string,
        },
      };
    }
    case ActionType.HourRuleDialogOpen: {
      const data = state.pageState.data;
      const currentHourSpecialId =
        data && data?.hourSpecials.length > 0 ? data.hourSpecials[0].id : null;
      const formattedHoursSpecial =
        currentHourSpecialId && data
          ? convertSpecialHourQueryResult(data.hourSpecials[0])
          : {};
      return {
        ...state,
        hourRuleDialog: {
          ...INITIAL_HOUR_RULE_DIALOG_STATE,
          currentHourBreakId: state.hourRuleDialog.currentHourBreakId,
          currentHourSpecialId: state.hourRuleDialog.currentHourSpecialId,
          hasOverlap: false,
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            ruleName: state.pageState.data?.name || "",
            weekdaysWithHours:
              state.hourRuleDialog.currentStandardWeekdayWithHours,
            newWeekdaysWithHours:
              state.hourRuleDialog.currentStandardWeekdayWithHours,
          },
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
          previousSpecialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedHoursSpecial,
          },
          currentStandardWeekdayWithHours:
            state.hourRuleDialog.currentStandardWeekdayWithHours,
          isOpen: true,
          dialogType: DialogType.EditHourRules,
        },
      };
    }
    case ActionType.HourRuleDialogClose: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isOpen: false,
        },
      };
    }
    case ActionType.GetBreakLoad: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          currentHourBreakId: value as number,
          isLoading: true,
        },
      };
    }
    case ActionType.GetBreakSuccess: {
      const formatStandard = convertDataToWeekdayWithHours(
        value.hourBreakStandards
      );
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: false,
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            dateRange: [
              DateTime.fromISO(value.startDate, {
                setZone: true,
              }),
              DateTime.fromISO(value.endDate, {
                setZone: true,
              }),
            ],
            previousDateRange: [
              DateTime.fromISO(value.startDate, {
                setZone: true,
              }),
              DateTime.fromISO(value.endDate, {
                setZone: true,
              }),
            ],
            id: value.id,
            breakName: value.name,
            previousBreakName: value.name,
            disabled: value.disabled,
            weekdaysWithHours: formatStandard,
            newWeekdaysWithHours: formatStandard,
          },
        },
      };
    }
    case ActionType.GetBreakError: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isLoading: false,
          errorMessage: value as string,
        },
      };
    }
    case ActionType.BreakNameChange: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            breakName: value as string,
          },
        },
      };
    }
    case ActionType.BreakDateRangeChange: {
      const { hourRuleDialog } = state;
      return {
        ...state,
        hourRuleDialog: {
          ...hourRuleDialog,
          breakHoursTab: {
            ...hourRuleDialog.breakHoursTab,
            dateRange: value as [DateTime | null, DateTime | null],
          },
        },
      };
    }
    case ActionType.AddHourBreakClick: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          currentHourBreakId: null,
          breakHoursTab: {
            ...INITIAL_STATE.hourRuleDialog.breakHoursTab,
            isAddMode: true,
          },
        },
      };
    }
    case ActionType.CancelAddHourBreakClick: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            isAddMode: false,
          },
        },
      };
    }
    case ActionType.AddHourSpecialClick: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          currentHourSpecialId: null,
          specialHoursTab: {
            ...INITIAL_STATE.hourRuleDialog.specialHoursTab,
            isAddMode: true,
          },
          previousSpecialHoursTab: {
            ...INITIAL_STATE.hourRuleDialog.specialHoursTab,
            isAddMode: true,
          },
        },
      };
    }
    case ActionType.CancelAddHourSpecialClick: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            isAddMode: false,
          },
        },
      };
    }
    case ActionType.HourSpecialNameChange: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            hourSpecialName: value as string,
          },
        },
      };
    }
    case ActionType.HourSpecialDateChange: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            date: value as DateTime,
          },
        },
      };
    }
    case ActionType.HourSpecialCheckChange: {
      const { name, checked } = value as { name: string; checked: boolean };
      // isAllOpen and isAllClose are mutually exclusive
      const isAllOpen = name === "isAllOpen" ? checked : false;
      const isAllClose = name === "isAllClose" ? checked : false;
      const newSetHour =
        isAllOpen || isAllClose
          ? { open: "", close: "", sliderValue: [0, 96] }
          : { open: "", close: "", sliderValue: [0, 0] };

      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            isAllOpen,
            isAllClose,
            isNineToFive: false,
            setHour: newSetHour,
          },
        },
      };
    }
    case ActionType.HourSpecialNineToFiveClick: {
      const { checked } = value;
      const newSetHour = checked
        ? { open: "09:00", close: "17:00", sliderValue: [36, 68] }
        : { open: "", close: "", sliderValue: [0, 0] };

      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          errorMessage: "",
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            isAllOpen: false,
            isAllClose: false,
            isNineToFive: checked,
            setHour: newSetHour,
          },
        },
      };
    }
    case ActionType.HourSpecialHourChange: {
      const { setHour } = state.hourRuleDialog.specialHoursTab;
      const { e } = value as { e: React.ChangeEvent<HTMLInputElement> };
      const newSliderValue = setHour.sliderValue ? setHour.sliderValue : [0, 0];
      const hourValue = Number(e.target.value.split(":")[0]);
      const minuteValue = Number(e.target.value.split(":")[1]);
      const minuteTicks = Math.floor(minuteValue / 15);
      const openSliderValue =
        e.target.name === "open"
          ? hourValue * 4 + minuteTicks
          : newSliderValue[0];
      let closeSliderValue =
        e.target.name === "close"
          ? hourValue * 4 + minuteTicks
          : newSliderValue[1];
      if (e.target.name === "close" && e.target.value < setHour.open) {
        closeSliderValue = 96;
      }
      const sliderValue = [openSliderValue, closeSliderValue];
      const newSetHour = {
        ...setHour,
        sliderValue,
        [e.target.name]: e.target.value,
      };
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          errorMessage: "",
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            setHour: newSetHour,
          },
        },
      };
    }
    case ActionType.HourSpecialSliderChange: {
      const { newValue } = value as { newValue: number[] };
      const isAllOpen = newValue[0] === 0 && newValue[1] === 96;
      const isNineToFive = newValue[0] === 36 && newValue[1] === 68;
      const newSetHour = convertSliderValueToSetHour(newValue);
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          errorMessage: "",
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            setHour: newSetHour,
            isAllOpen,
            isNineToFive,
            isAllClose: false,
          },
        },
      };
    }
    case ActionType.HourSpecialIdSelect: {
      const formattedValue = convertSpecialHourQueryResult(
        value as HourSpecialQueryResult
      );
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          currentHourSpecialId: value.id as number,
          specialHoursTab: {
            ...state.hourRuleDialog.specialHoursTab,
            ...formattedValue,
          },
        },
      };
    }
    case ActionType.SliderSetHourChange: {
      const {
        newValue,
        dayId,
        index,
        tabName,
      }: {
        newValue: number[];
        dayId: number;
        index: number;
        tabName: string;
      } = value;
      const isAlwaysOpen = newValue[0] === 0 && newValue[1] === 96;
      const isNineToFive = newValue[0] === 36 && newValue[1] === 68;
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      const newWeekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      let hasOverlap = false;

      const updatedStandard = newWeekdaysWithHours.map((weekday) => {
        if (weekday.day.id === dayId) {
          const newSetHours = weekday.setHours.map((hour, i) => {
            if (i === index) {
              const newSetHourValue = convertSliderValueToSetHour(newValue);
              return {
                ...hour,
                open: newSetHourValue.open,
                close: newSetHourValue.close,
                sliderValue: newValue,
              };
            }
            return hour;
          });
          hasOverlap = checkForOverlap(newSetHours);
          return {
            ...weekday,
            isAlwaysOpen,
            isNineToFive,
            isAlwaysClosed: false,
            setHours: isAlwaysOpen
              ? [{ open: "", close: "", sliderValue: [0, 96] }]
              : newSetHours,
          };
        }
        return weekday;
      });
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.SetHourChange: {
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      const { index, e, dayId, tabName } = value as {
        index: number;
        e: React.ChangeEvent<HTMLInputElement>;
        dayId: number;
        tabName: string;
      };
      let hasOverlap = false;
      const weekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      const updatedStandard = weekdaysWithHours.map((weekday) => {
        if (weekday.day.id === dayId) {
          const newSetHours = weekday.setHours.map((hour, i) => {
            if (i === index) {
              const hourValue = Number(e.target.value.split(":")[0]);
              const minuteValue = Number(e.target.value.split(":")[1]);
              const minuteTicks = Math.floor(minuteValue / 15);
              const newSliderValue = hour.sliderValue
                ? hour.sliderValue
                : [0, 0];
              const openSliderValue =
                e.target.name === "open"
                  ? hourValue * 4 + minuteTicks
                  : newSliderValue[0];
              let closeSliderValue =
                e.target.name === "close"
                  ? hourValue * 4 + minuteTicks
                  : newSliderValue[1];
              if (e.target.name === "close" && e.target.value < hour.open) {
                closeSliderValue = 96;
              }
              const sliderValue = [openSliderValue, closeSliderValue];
              return {
                ...hour,
                sliderValue,
                [e.target.name]: e.target.value,
              };
            }
            return hour;
          });
          hasOverlap = checkForOverlap(newSetHours);
          return {
            ...weekday,
            setHours: newSetHours,
          };
        }
        return weekday;
      });
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.AddSetHour: {
      const { dayId, tabName } = value;
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      const weekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      const updatedStandard = weekdaysWithHours.map((weekday) => {
        if (weekday.day.id === dayId) {
          return {
            ...weekday,
            setHours: [
              ...weekday.setHours,
              { open: "", close: "", sliderValue: [0, 0] },
            ],
          };
        }
        return weekday;
      });
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.RemoveSetHourById: {
      const { dayId, index, tabName } = value;
      if (index === 0) {
        return state;
      }
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      let hasOverlap = false;
      const weekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      const updatedStandard = weekdaysWithHours.map((weekday) => {
        if (weekday.day.id === dayId) {
          const newSetHour = weekday.setHours.filter((_, i) => i !== index);
          hasOverlap = checkForOverlap(newSetHour);
          return {
            ...weekday,
            setHours: newSetHour,
          };
        }
        return weekday;
      });
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.CheckBoxChange: {
      const { dayId, tabName, name, checked } = value;
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      const weekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      // isAllOpen and isAllClose are mutually exclusive
      const isAlwaysClosed = name === "isAlwaysClosed" ? checked : false;
      const isAlwaysOpen = name === "isAlwaysOpen" ? checked : false;
      const updatedStandard = weekdaysWithHours.map((weekday) => {
        if (weekday.day.id === dayId) {
          const newSetHours =
            isAlwaysOpen || isAlwaysClosed
              ? [{ open: "", close: "", sliderValue: [0, 96] }]
              : [{ open: "", close: "", sliderValue: [0, 0] }];
          return {
            ...weekday,
            isAlwaysClosed,
            isAlwaysOpen,
            isNineToFive: false,
            setHours: newSetHours,
          };
        }
        return weekday;
      });
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap: false,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.NineToFiveClick: {
      const { dayId, tabName, checked } = value;
      const {
        hourRuleDialog: { standardTab, breakHoursTab },
      } = state;
      const weekdaysWithHours =
        tabName === "standardTab"
          ? standardTab.newWeekdaysWithHours
          : breakHoursTab.newWeekdaysWithHours;
      const updatedStandard: WeekdayWithHours[] = weekdaysWithHours.map(
        (weekday) => {
          if (weekday.day.id === dayId) {
            const newSetHours = checked
              ? [{ open: "09:00", close: "17:00", sliderValue: [36, 68] }]
              : [{ open: "", close: "", sliderValue: [0, 0] }];
            return {
              ...weekday,
              isAlwaysOpen: false,
              isAlwaysClosed: false,
              isNineToFive: checked,
              setHours: newSetHours,
            };
          }
          return weekday;
        }
      );
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          hasOverlap: false,
          errorMessage: "",
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours:
              tabName === "standardTab"
                ? updatedStandard
                : standardTab.newWeekdaysWithHours,
          },
          breakHoursTab: {
            ...state.hourRuleDialog.breakHoursTab,
            newWeekdaysWithHours:
              tabName === "breakHoursTab"
                ? updatedStandard
                : breakHoursTab.newWeekdaysWithHours,
          },
        },
      };
    }
    case ActionType.GetCustomerHourRulesLoad: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isOpen: true,
          errorMessage: "",
          dialogType: DialogType.AssignHourrule,
          assignHourRules: {
            ...state.hourRuleDialog.assignHourRules,
            isLoading: true,
            errorMessage: "",
          },
        },
      };
    }
    case ActionType.GetCustomerHourRulesSuccess: {
      const currentSelectedHourRule = value.customerHourRules.find(
        (rule: HourRule) => rule.id === state.pageState.data?.id
      );
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          assignHourRules: {
            ...state.hourRuleDialog.assignHourRules,
            isLoading: false,
            data: value,
            currentSelectedHourRule: currentSelectedHourRule || null,
          },
        },
      };
    }
    case ActionType.GetCustomerHourRulesError: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          assignHourRules: {
            ...state.hourRuleDialog.assignHourRules,
            isLoading: false,
            errorMessage: value as string,
          },
        },
      };
    }
    case ActionType.HourRuleSelection: {
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          assignHourRules: {
            ...state.hourRuleDialog.assignHourRules,
            currentSelectedHourRule: value as HourRule,
          },
        },
      };
    }
    case ActionType.CreateHourRuleClick: {
      const { standardTab } = state.hourRuleDialog;
      const isGloabalRule = state.pageState.data?.customerId
        ? state.pageState.data?.customerId === 1
        : false;
      return {
        ...state,
        hourRuleDialog: {
          ...state.hourRuleDialog,
          isOpen: true,
          errorMessage: "",
          dialogType: DialogType.CreateHourRule,
          standardTab: {
            ...state.hourRuleDialog.standardTab,
            newWeekdaysWithHours: isGloabalRule
              ? standardTab.newWeekdaysWithHours
              : INITIAL_STANDARD,
            weekdaysWithHours: isGloabalRule
              ? standardTab.weekdaysWithHours
              : INITIAL_STANDARD,
            ruleName: "",
          },
        },
      };
    }
    default:
      throw new Error("Hour Dialog State unexpected action");
  }
}
