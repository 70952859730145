import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  mt?: number;
  p?: number;
}

const LoadingCircle: React.FC<Props> = ({ mt = 20, p = 6 }) => {
  return (
    <Box
      sx={{
        mt,
        p,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingCircle;
