import React from "react";
import { useNavigate } from "react-router-dom";
import EmptyStateCardContent from "../EmptyStateCardContent";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Box from "@mui/material/Box";

interface Props {
  buttonLabel?: string;
  path?: string;
  reload?: boolean;
}

const NetworkErrorAlert: React.FC<Props> = ({
  buttonLabel = "Go to home",
  path = "/",
  reload = false,
}) => {
  const navigate = useNavigate();

  const handleNavigationClick = () => {
    navigate(path);
    if (reload) {
      window.location.reload();
    }
    return;
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <EmptyStateCardContent
        action={{ onClick: handleNavigationClick, label: buttonLabel }}
        Icon={
          <ReportGmailerrorredIcon
            sx={{ color: "grey.700" }}
            fontSize="large"
          />
        }
        text="An unexpected error occurred."
        subtitle={
          <>
            Please try again, or contact{" "}
            <a href="mailto:support@occuspace.io">support@occuspace.io</a> for
            assistance
          </>
        }
      />
    </Box>
  );
};

export default NetworkErrorAlert;
