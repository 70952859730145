import React, { useContext } from "react";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
import { OccuUser, Products, SpaceType } from "./interfaces"; // TODO: import spacetype from common
import { isAdmin, isSetupRole } from "./util/permissions";
import GenericError from "./components/GenericError";
import ViewContext from "./ViewContext";
import TreeContext from "./TreeContext";
import {
  AppRoutes,
  getCurrentFirstPath,
  spacesRoutes,
  SpaceRoutes,
  AnalyticsRoute,
  deriveSpaceId,
} from "./routing";
import { useLocationChange } from "./occu-portal-t";

const Analytics = React.lazy(() => import("./views/Analytics"));
const AnalyticsSpace = React.lazy(() => import("./views/AnalyticsSpace"));
const LiveData = React.lazy(() => import("./views/LiveData"));
const LiveDataSpace = React.lazy(() => import("./views/LiveDataSpace"));
const MyAccount = React.lazy(() => import("./views/MyAccount"));
const Space = React.lazy(() => import("./views/Space"));
const User = React.lazy(() => import("./views/User"));
const UserManagement = React.lazy(() => import("./views/UserManagement"));

interface Props {
  user: OccuUser;
}

const OccuRouter: React.FC<Props> = ({ user }) => {
  useLocationChange();
  const { isMobileSized } = useContext(ViewContext);
  const { trees, nodes, products, hasNoSpaces } = useContext(TreeContext);
  const hasOccupancy = products.includes(Products.Occupancy);
  const { pathname } = useLocation();
  const firstPath = getCurrentFirstPath(pathname);
  const splitPathname = pathname.split("/");
  const spaceId = deriveSpaceId(firstPath, splitPathname);
  const spaceNode = spaceId ? nodes[spaceId] : null;

  const { role } = user;

  const deriveIndexNavigation = () => {
    if (hasNoSpaces) {
      return <Navigate to={`/${AppRoutes.Spaces}/${SpaceRoutes.WiFiCreds}`} />;
    }
    if (isSetupRole(role)) {
      return <Navigate to={`/${AppRoutes.Spaces}/${SpaceRoutes.Overview}`} />;
    }

    if (isMobileSized) {
      if (hasOccupancy) {
        if (trees.length === 1) {
          return <Navigate to={`/${AppRoutes.LiveData}/${trees[0].id}`} />;
        } else {
          return <Navigate to={`/${AppRoutes.LiveData}`} />;
        }
      }
      if (trees.length === 1) {
        return (
          <Navigate
            to={`/${AppRoutes.Analytics}/${trees[0].id}/${AnalyticsRoute.Overview}`}
          />
        );
      } else {
        return <Navigate to={`/${AppRoutes.Analytics}`} />;
      }
    }

    if (trees.length === 1) {
      return (
        <Navigate
          to={`/${AppRoutes.Analytics}/${trees[0].id}/${AnalyticsRoute.Overview}`}
        />
      );
    } else {
      return <Navigate to={`/${AppRoutes.Analytics}`} />;
    }
  };

  // if the user only has one tree, they request a spaces route, and there is no space id
  // route them to the root id of the tree

  // TODO: route from 'analytics/id to analytics/id/overview' if they have 1 tree
  if (
    trees.length === 1 &&
    spacesRoutes.has(firstPath as AppRoutes) &&
    !spaceId
  ) {
    if (firstPath === AppRoutes.Spaces) {
      return (
        <Navigate
          to={`/${AppRoutes.Spaces}/${trees[0].id}/${SpaceRoutes.Overview}`}
          replace
        />
      );
    }
    if (firstPath === AppRoutes.Analytics) {
      return (
        <Navigate
          to={`/${AppRoutes.Analytics}/${trees[0].id}/${AnalyticsRoute.Overview}`}
          replace
        />
      );
    }
    return <Navigate to={`/${firstPath}/${trees[0].id}`} replace />;
  }

  // Check if userId isNan on user page
  if (firstPath === AppRoutes.Users) {
    const userId = Number(splitPathname[2]);
    if (Number.isNaN(userId)) {
      return <Navigate to="/" replace />;
    }
  }

  // everyone gets spaces
  const routes = [
    <Route
      key={`/${AppRoutes.Spaces}`}
      path={`/${AppRoutes.Spaces}`}
      element={<Space />}
    />,
    <Route
      key={`/${AppRoutes.Spaces}/:spaceId/:selectedTab`}
      path={`/${AppRoutes.Spaces}/:spaceId/:selectedTab`}
      element={<Space />}
    />,
    // can be /spaceId/overview, or /overview
    <Route
      key={`/${AppRoutes.Spaces}/:firstParam`}
      path={`/${AppRoutes.Spaces}/:firstParam`}
      element={<Space />}
    />,
  ];

  if (!isSetupRole(role)) {
    // if the user tries to view analytics or live data for a filtration space
    // send them back to analytics or live data root
    // should we check to see if they have 1 tree to send them to that root space?
    if (
      spaceNode &&
      spaceNode?.type === SpaceType.Filtration &&
      (firstPath === AppRoutes.Analytics || firstPath === AppRoutes.LiveData)
    ) {
      return <Navigate to={`/${firstPath}`} replace />;
    }

    if (!hasNoSpaces) {
      routes.push(
        <Route
          key={`/${AppRoutes.Analytics}/:spaceId/:selectedTab`}
          path={`/${AppRoutes.Analytics}/:spaceId/:selectedTab`}
          element={<AnalyticsSpace />}
        />,
        <Route
          key={`/${AppRoutes.Analytics}/:spaceId`}
          path={`/${AppRoutes.Analytics}/:spaceId`}
          element={<AnalyticsSpace />}
        />,
        <Route
          key={`/${AppRoutes.Analytics}`}
          path={`/${AppRoutes.Analytics}`}
          element={<Analytics />}
        />
      );
    }
    // Only grant access to live data if the user has occupancy
    if (hasOccupancy && !hasNoSpaces) {
      routes.push(
        <Route
          key={`/${AppRoutes.LiveData}/:spaceId`}
          path={`/${AppRoutes.LiveData}/:spaceId`}
          element={<LiveDataSpace />}
        />,
        <Route
          key={`/${AppRoutes.LiveData}`}
          path={`/${AppRoutes.LiveData}`}
          element={<LiveData />}
        />
      );
    }
  }

  if (isAdmin(role)) {
    routes.push(
      <Route
        key={`/${AppRoutes.UserManagement}`}
        path={`/${AppRoutes.UserManagement}`}
        element={<UserManagement />}
      />,
      <Route
        key={`/${AppRoutes.Users}/:userId`}
        path={`/${AppRoutes.Users}/:userId`}
        element={<User />}
      />
    );
  }

  routes.push(
    <Route
      key={`/${AppRoutes.MyAccount}`}
      path={`/${AppRoutes.MyAccount}`}
      element={<MyAccount />}
    />,
    <Route
      key="*"
      path="*"
      element={
        <GenericError
          buttonLabel="Go to home"
          path="/"
          message="Route not found"
        />
      }
    />
  );

  routes.push(
    <Route key="/dashboard" path="/dashboard" element={<Navigate to={`/`} />} />
  );
  routes.push(<Route key="/" index element={deriveIndexNavigation()} />);

  return <Routes>{routes}</Routes>;
};

export default OccuRouter;
