import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LoadingButton from "@mui/lab/LoadingButton";

interface Props {
  isEditing: boolean;
  isLoading: boolean;
  input: React.ReactNode;
  errorMessage: string;
  isSubmitDisabled: boolean;
  onEditClick: () => void;
  onEditCancelClick: () => void;
  onSubmitClick: () => void;
  value: string;
  title: string;
}

const EditableAttributeValue: React.FC<Props> = ({
  isEditing,
  isLoading,
  input,
  errorMessage,
  isSubmitDisabled,
  onEditClick,
  onEditCancelClick,
  onSubmitClick,
  value,
  title,
}) => {
  const theme = useTheme();
  const editIconId = `edit-icon-${title}`;
  if (!isEditing) {
    return (
      <Box
        sx={{
          // When hovering on the box, make edit icon visible
          [`&:hover #${editIconId}`]: {
            visibility: "visible",
            opacity: 1,
            transition: `visibility 0s linear 0s, opacity ${theme.transitions.duration.shorter}ms`,
          },
        }}
      >
        <Typography variant="label" gutterBottom>
          {title}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography mr={1} noWrap>
            {value}
          </Typography>
          <IconButton
            size="small"
            onClick={onEditClick}
            id={editIconId}
            sx={{ visibility: "hidden", opacity: 0 }}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="label">{title}</Typography>
        <Button
          onClick={onEditCancelClick}
          sx={{ alignSelf: "end" }}
          disabled={isLoading}
          size="small"
        >
          Cancel
        </Button>
      </Box>
      {input}

      <LoadingButton
        fullWidth
        variant="contained"
        disableElevation
        onClick={onSubmitClick}
        disabled={isSubmitDisabled}
        loading={isLoading}
        sx={{ mt: 2 }}
      >
        Save
      </LoadingButton>
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default EditableAttributeValue;
