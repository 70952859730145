import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";
import { SvgIconProps } from "@mui/material/SvgIcon";

const ZoneSvgIcon = createSvgIcon(
  <path
    d="M5.21 7.951 12 11.975l6.79-4.024M12 20v-8.033m7 3.188V8.78c0-.28-.072-.554-.209-.796a1.575 1.575 0 0 0-.569-.583l-5.444-3.187a1.527 1.527 0 0 0-1.556 0L5.778 7.4c-.236.14-.433.34-.57.583A1.624 1.624 0 0 0 5 8.78v6.375c0 .28.072.554.209.796.136.242.333.443.569.582l5.444 3.188a1.527 1.527 0 0 0 1.556 0l5.444-3.188c.236-.14.433-.34.57-.582.136-.242.208-.517.208-.796Z"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  />,
  "Zone"
);

// https: mui.com/material-ui/react-tooltip/#custom-child-element
const Zone = React.forwardRef<HTMLDivElement, SvgIconProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <ZoneSvgIcon viewBox="0 0 24 24" {...props} ref={ref as any} />;
});

export default Zone;
