import React, { useMemo, Dispatch, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Action,
  HourSpecialTabState,
  handleHourSpecialNameChange,
  handleHourSpecialDateChange,
  handleHourSpecialCheckChange,
  handleHourSpecialHourChange,
  handleHourSpecialIdSelect,
  handleHourSpecialSliderChange,
  handleHourSpecialNineToFiveClick,
  deriveSliderColor,
  handleAddHourSpecialClick,
} from "../../state/hours-dialog";
import { SetHour, formatToTwelveHour } from "common/dates";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { DateTime } from "luxon";
import HoursSlider from "../HoursSlider";

interface Props {
  dispatch: Dispatch<Action>;
  hourSpecials: AnyVal[];
  specialHoursTab: HourSpecialTabState;
  currentHourSpecialId: number | null;
  isLoading: boolean;
}

const step = 300;

const iconButtonStyles = {
  bgcolor: "grey.200",
  p: 1,
};

interface OpenCloseTimeRangeProps {
  dispatch: Dispatch<Action>;
  setHour: SetHour;
  isLoading: boolean;
}

const OpenCloseTimeRange: React.FC<OpenCloseTimeRangeProps> = ({
  dispatch,
  setHour: { open, close },
  isLoading,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        "& div.MuiOutlinedInput-root.MuiInputBase-root": {
          py: 1,
          px: 2,
        },
      }}
    >
      <TextField
        disabled={isLoading}
        type="time"
        name="open"
        value={open}
        onChange={handleHourSpecialHourChange(dispatch)}
        inputProps={{ step }}
        size="small"
      />
      <Typography variant="body2" color="grey.500" px={2}>
        TO
      </Typography>
      <TextField
        disabled={isLoading}
        type="time"
        name="close"
        value={close}
        onChange={handleHourSpecialHourChange(dispatch)}
        inputProps={{ step }}
        size="small"
      />
    </Box>
  );
};

const HoursLabelWithSlider = ({
  isAllClose,
  isAllOpen,
  setHour,
  dispatch,
  isLoading,
  sliderMaxWidth,
}: {
  isAllClose: boolean;
  isAllOpen: boolean;
  setHour: SetHour;
  dispatch: Dispatch<Action>;
  isLoading: boolean;
  sliderMaxWidth?: number;
}) => {
  const currentColor = useMemo(
    () => `${deriveSliderColor(isAllClose)}.main`,
    [isAllOpen, isAllClose]
  );
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const handleIsEditingClick = () => {
    setIsEditing((prevState) => !prevState);
  };
  const { open, close } = setHour;

  const hourText = isAllClose
    ? "Closed"
    : isAllOpen
    ? "Open 24 hours"
    : open && close
    ? `${formatToTwelveHour(open)} - ${formatToTwelveHour(close)}`
    : "No hours set";

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
    >
      <HoursSlider
        currentColor={currentColor}
        sliderMaxWidth={sliderMaxWidth}
        sliderValue={setHour.sliderValue as number[]}
        isLoading={isLoading}
        onSliderChange={handleHourSpecialSliderChange(dispatch)}
      />
      <Box display="flex" alignItems="center" fontSize={16} gap={2}>
        {isEditing ? (
          <OpenCloseTimeRange
            dispatch={dispatch}
            setHour={setHour}
            isLoading={isLoading}
          />
        ) : (
          <Typography>{hourText}</Typography>
        )}
        <IconButton
          disabled={isLoading}
          sx={iconButtonStyles}
          onClick={handleIsEditingClick}
        >
          {isEditing ? (
            <CheckIcon sx={{ fontSize: 18 }} />
          ) : (
            <EditOutlinedIcon sx={{ fontSize: 18 }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

interface HourSliderItemProps {
  isAllClose: boolean;
  isAllOpen: boolean;
  isNineToFive: boolean;
  setHour: SetHour;
  dispatch: Dispatch<Action>;
  isLoading: boolean;
  date: DateTime | null;
  sliderMaxWidth?: number;
}

const HoursSliderItem: React.FC<HourSliderItemProps> = ({
  isAllClose,
  isAllOpen,
  isNineToFive,
  setHour,
  isLoading,
  dispatch,
  date,
  sliderMaxWidth,
}) => {
  const weekdayString = date ? date.toFormat("cccc") : "Select a date";
  return (
    <>
      <Box py={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontWeight={600}>{weekdayString}</Typography>
          <Box display="flex" gap={4}>
            <FormControlLabel
              disabled={isLoading}
              sx={{ mr: 0 }}
              control={
                <Checkbox
                  sx={{ p: 1 }}
                  size="small"
                  checked={isNineToFive}
                  onChange={handleHourSpecialNineToFiveClick(dispatch)}
                  name="isNineToFive"
                />
              }
              label={<Typography variant="label">9-5</Typography>}
            />
            <FormControlLabel
              disabled={isLoading}
              sx={{ mr: 0 }}
              control={
                <Checkbox
                  sx={{ p: 1 }}
                  size="small"
                  checked={isAllOpen}
                  onChange={handleHourSpecialCheckChange(dispatch)}
                  name="isAllOpen"
                />
              }
              label={<Typography variant="label">24/7</Typography>}
            />
            <FormControlLabel
              disabled={isLoading}
              sx={{ mr: 0 }}
              control={
                <Checkbox
                  sx={{ p: 1 }}
                  size="small"
                  checked={isAllClose}
                  onChange={handleHourSpecialCheckChange(dispatch)}
                  name="isAllClose"
                />
              }
              label={<Typography variant="label">Closed</Typography>}
            />
          </Box>
        </Box>
        <HoursLabelWithSlider
          isAllClose={isAllClose}
          isAllOpen={isAllOpen}
          setHour={setHour}
          dispatch={dispatch}
          isLoading={isLoading}
          sliderMaxWidth={sliderMaxWidth}
        />
      </Box>
      <Divider />
    </>
  );
};

const SpecialHoursContent: React.FC<Props> = ({
  dispatch,
  hourSpecials,
  specialHoursTab: {
    date,
    hourSpecialName,
    isAllClose,
    isAllOpen,
    isNineToFive,
    setHour,
    isAddMode,
  },
  currentHourSpecialId,
  isLoading,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const handleDatePickerOpen = () => {
    setIsDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
  };

  if (!isAddMode && (!hourSpecials || hourSpecials?.length === 0)) {
    return (
      <Box
        height={450}
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DateRangeIcon
          sx={{ color: "grey.300", fontSize: 200 }}
          fontSize="large"
        />
        <Typography
          variant="h3"
          align="center"
          fontWeight={600}
          color="grey.500"
          gutterBottom
        >
          No single-day special hours exist yet
        </Typography>
        <Typography variant="h5" align="center" color="grey.400">
          <Link
            underline="none"
            color="primary"
            sx={{ cursor: "pointer", fontSize: "inherit" }}
            onClick={handleAddHourSpecialClick(dispatch)}
          >
            Create a new special hours entry
          </Link>{" "}
          for a specific date (single day)
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" gap={4}>
      {!isAddMode && (
        <Box flex={1} borderRight={1} borderColor="grey.200" pr={4}>
          <Divider />
          <Box
            sx={{
              overflowY: "auto",
              height: 450,
            }}
          >
            {hourSpecials &&
              hourSpecials.length > 0 &&
              hourSpecials.map((hourSpecial, index) => {
                const isSelected = hourSpecial.id === currentHourSpecialId;
                const date = DateTime.fromISO(hourSpecial.day, {
                  setZone: true,
                }).toLocaleString(DateTime.DATE_MED);
                const subTitle = hourSpecial.name || "-";
                return (
                  <Box
                    key={index}
                    p={2}
                    borderBottom={1}
                    borderColor="grey.200"
                    sx={{
                      "&:hover": {
                        backgroundColor: "grey.200",
                        cursor: "pointer",
                      },
                      backgroundColor: isSelected ? "grey.100" : "transparent",
                    }}
                    onClick={handleHourSpecialIdSelect(dispatch, hourSpecial)}
                  >
                    <Box>
                      <Typography variant="body2" fontWeight={600}>
                        {date}
                      </Typography>
                      <Typography variant="body2" color="grey.600">
                        {subTitle}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
      {currentHourSpecialId || isAddMode ? (
        <Box flex={4} sx={{ opacity: isLoading ? 0.5 : 1 }}>
          <DatePicker
            disabled={isLoading}
            open={isDatePickerOpen}
            onOpen={handleDatePickerOpen}
            onClose={handleDatePickerClose}
            label="Date"
            value={date}
            onChange={handleHourSpecialDateChange(dispatch)}
            renderInput={(params) => (
              <TextField
                disabled={isLoading}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "dd/mm/yyyy",
                }}
                onClick={handleDatePickerOpen}
                sx={{ mt: 4, mb: 2 }}
              />
            )}
            InputAdornmentProps={{ position: "start" }}
            components={{
              OpenPickerIcon: CalendarTodayOutlinedIcon,
            }}
            disableMaskedInput={true}
          />

          <TextField
            disabled={isLoading}
            value={hourSpecialName}
            onChange={handleHourSpecialNameChange(dispatch)}
            placeholder="Name your special hours day"
            fullWidth
            size="small"
            autoComplete="special-hours-name"
            label="Special Hours Name"
            sx={{
              mt: 6,
            }}
          />
          <Box flex={1} mt={1}>
            <HoursSliderItem
              isAllClose={isAllClose}
              isAllOpen={isAllOpen}
              isNineToFive={isNineToFive}
              setHour={setHour}
              dispatch={dispatch}
              isLoading={isLoading}
              sliderMaxWidth={480}
              date={date}
            />
          </Box>
        </Box>
      ) : (
        <Box
          flex={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DateRangeIcon
            sx={{ color: "grey.300", fontSize: 200 }}
            fontSize="large"
          />
          <Typography
            variant="h3"
            align="center"
            fontWeight={600}
            color="grey.500"
            gutterBottom
          >
            No single-day special hours selected
          </Typography>
          <Typography variant="h5" align="center" color="grey.400">
            Select a special hours entry for a specific date (single day)
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default SpecialHoursContent;
