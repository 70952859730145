import React from "react";
import Card from "@mui/material/Card";
import VerticalLogoIcon from "../../icons/VerticalLogo";

interface Props {
  children: React.ReactNode;
  isLarge?: boolean;
  isSmall?: boolean;
}

const LoginCard: React.FC<Props> = ({ children, isLarge, isSmall }) => {
  const cardWidth = () => {
    if (isSmall) return 400;
    if (isLarge) return 500;
    return 450;
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        p: 10,
        maxWidth: { xs: "100%", sm: cardWidth() },
      }}
    >
      <VerticalLogoIcon />
      {children}
    </Card>
  );
};

export default LoginCard;
