import { ErrorBoundary } from "react-error-boundary";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import NetworkErrorAlert from "../NetworkErrorAlert";
import { errorHandler } from "../../handlers";
import OccuAppBar from "../../components/OccuAppBar";

interface Props {
  children: React.ReactChild;
}

const LoggedInErrorBoundary: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const isMobileSized = useMediaQuery(theme.breakpoints.down("sm"));

  const ErrorFallback = () => {
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <OccuAppBar isMobileSized={isMobileSized} logoutOnly />
        <Box flexGrow={1} minHeight={0} display="flex">
          <NetworkErrorAlert reload />
        </Box>
      </Box>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  );
};

export default LoggedInErrorBoundary;
