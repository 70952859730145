import React from "react";
import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { formatNumberWithComma } from "../../util/format";
import { Skeleton } from "@mui/material";

interface Props {
  occupancy?: number;
  percent?: number;
  typographyStyles?: TypographyProps;
  isSkeleton?: boolean;
}

const LiveData: React.FC<Props> = ({
  occupancy,
  percent,
  typographyStyles,
  isSkeleton,
}) => {
  const skeletonWidth = 60;
  const barPercent = (() => {
    if (isSkeleton || !percent) return 0;
    if (percent > 100) return 100;
    return percent;
  })();

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography {...typographyStyles}>
          {isSkeleton && <Skeleton variant="text" width={skeletonWidth} />}
          {Number.isInteger(occupancy) &&
            `${formatNumberWithComma(occupancy as number)} People`}
        </Typography>
        <Typography {...typographyStyles}>
          {isSkeleton && <Skeleton variant="text" width={skeletonWidth} />}
          {Number.isInteger(percent) && `${percent}% Full`}
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={barPercent} />
    </>
  );
};

export default LiveData;
