import React, { useState } from "react";
import Box from "@mui/material/Box";
import GreyBoxLabel from "./GreyBoxLabel";
import FloorPlanDialog from "../FloorPlanDialog";
import { FloorPlan } from "../../interfaces";
import ImageLoader, { ImageOnLoad } from "../ImageLoader";
import { Space } from "../../interfaces/space";

interface Props {
  onImgRender: ImageOnLoad;
  space: Space;
}

const FloorPlans: React.FC<Props> = ({ space, onImgRender }) => {
  const { name, floorPlans } = space;
  const thumbnailUrl = (floorPlans as FloorPlan[])[0].url;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleFloorPlanOpenClick = () => {
    setIsDialogOpen(true);
  };

  const handleFloorPlanCloseClick = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <GreyBoxLabel title="Floor Plans" />
      <ImageLoader src={thumbnailUrl} onLoad={onImgRender}>
        <Box
          component="img"
          width="100%"
          src={thumbnailUrl}
          alt={`${name} thumbnail`}
          onClick={handleFloorPlanOpenClick}
          sx={{ "&:hover": { cursor: "pointer" } }}
        />
      </ImageLoader>
      {isDialogOpen && (
        <FloorPlanDialog onClose={handleFloorPlanCloseClick} space={space} />
      )}
    </>
  );
};

export default FloorPlans;
