import { SelectChangeEvent } from "@mui/material";
import { Map, UserRole, UserStatus } from "../../interfaces";

interface AddUserInput {
  hasAvailableEmail: boolean;
  emailError: string;
  email: string;
  role: string;
}

export interface AddUserDialogState {
  isLoading: boolean;
  errorMessage: string;
  isOpen: boolean;
  selectedSpaceIds: string[];
  selectedSpaceIdMap: Map;
  input: AddUserInput;
}

export interface Filters {
  search: string;
  selectedRoles: string[];
  selectedStatuses: string[];
  currentPage: number;
}

export const defaultFilters = {
  search: "",
  selectedRoles: [],
  selectedStatuses: [],
  currentPage: 1,
};

export type ChangeStatusFn = (
  userStatuses: { label: string; value: UserStatus }[]
) => (event: SelectChangeEvent<string[]>) => void;

export type ChangeRoleFn = (
  userRoles: { label: string; value: UserRole }[]
) => (event: SelectChangeEvent<string[]>) => void;

export interface UserRoleMenuItem {
  label: string;
  value: UserRole;
}

export interface UserStatusMenuItem {
  label: string;
  value: UserStatus;
}
