import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  title: string | React.ReactElement;
  onClose: () => void;
  disabled?: boolean;
}

const DialogHeader: React.FC<Props> = ({ title, onClose, disabled }) => {
  return (
    <DialogTitle>
      {title}
      <IconButton
        aria-label={`Close ${title} dialog`}
        onClick={onClose}
        sx={{
          alignSelf: "center",
        }}
        disabled={disabled}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogHeader;
