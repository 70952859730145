import { BrowserTracker } from "@snowplow/browser-tracker";
import React from "react";

interface TrackerContext {
  tracker: BrowserTracker | undefined;
}

const TrackerContext = React.createContext<TrackerContext>({
  tracker: undefined,
});

export default TrackerContext;
