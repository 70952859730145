import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
}

const GreyBoxLabel: React.FC<Props> = ({ title }) => {
  return (
    <Typography bgcolor="grey.200" p={2} mx={-2} variant="label">
      {title}
    </Typography>
  );
};

export default GreyBoxLabel;
